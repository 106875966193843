import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiCall, apiFileUploadCall } from "../../../API";

export const getAllCreditNotesAction = createAsyncThunk(
    "CreditNote/GetAllCreditNote",
    async (data) => {
        return apiCall(`CreditNote`, "GET", {}, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {

                        return {
                            ...response.data,
                        }
                    } else {
                        //  console.log("response error", response.data.message);
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);
export const insertUpdateCreditNoteAction = createAsyncThunk(
    "CreditNote/insertupdatecreditnote",
    async (data) => {
        return apiCall("creditnote/add", "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        // console.log('response.data ',response.data.message)
                        let message = response.data.message
                        // console.log("message",message);
                        return {
                            data: {
                                ...response.data,
                            },
                            message: {
                                code: response.data.message.code,
                                type: response.data.message.type,
                                message: response.data.message.message
                            },
                            newRecord: data.id ? false : true
                        }
                    } else {
                        // console.log("response error", response.data.message);
                        if (!response.data.message && response.data.errors && response.data.errors.length) {
                            response.data.message = response.data.errors[0];
                        }
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
                else {
                    return Promise.reject({
                        ...response.data,
                    });
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);
export const getCreditNoteAction = createAsyncThunk(
    "CreditNote/GetCreditNote",
    async (data) => {
        return apiCall("creditnote/getcreditnote", "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        return {
                            ...response.data,
                        }
                    } else {
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);
export const activeInactiveCreditNoteAction = createAsyncThunk(
    "CreditNote/ActiveInActive",
    async (data) => {
        return apiCall("CreditNote/activeinactive", "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {

                        return {
                            ...response.data,
                        }
                    } else {
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);


export const addCreditNoteDocumentAction = createAsyncThunk(
    "CreditNote/addCreditNoteDocument",
    async (data) => {
        return apiFileUploadCall("creditnote/addCreditNoteDocument", "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        return {
                            ...response.data,
                        }
                    } else {
                        //  console.log("response error", response.data.message);
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);

export const getCreditNoteDocumentsAction = createAsyncThunk(
    "CreditNote/getCreditNoteDocuments",
    async (data) => {
        return apiCall("creditnote/getCreditNoteDocuments", "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        return {
                            ...response.data,
                        }
                    } else {
                        //  console.log("response error", response.data.message);
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);


export const deleteCreditNoteDocumentsAction = createAsyncThunk(
    "CreditNote/removecreditnotedocument",
    async (data) => {
        return apiCall("creditnote/removecreditnoteDocument", "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {

                        return {
                            ...response.data,
                        }
                    } else {
                        //  console.log("response error", response.data.message);
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);


export const getPaymentMethods = createAsyncThunk(
    "CreditNote/GetAllPaymentMethods",
    async (data) => {
      return apiCall("paymentmethod", "GET", data, false)
        .then((response) => {
          if (response.status === 200) {
            if (!response.data.hasError) {
  
              return {
                ...response.data,
              }
            } else {
              //  console.log("response error", response.data.message);
              return Promise.reject({
                ...response.data.message,
              });
            }
          }
        })
        .catch((error) => {
          return Promise.reject({
            ...error,
          });
        });
    }
  );
  
  export const getAllSupplierAction = createAsyncThunk(
    "CreditNote/GetAllSupplier",
    async (data) => {
      return apiCall(`supplier`, "POST", data, false)
        .then((response) => {
          if (response.status === 200) {
            if (!response.data.hasError) {
  
              return {
                ...response.data,
              }
            } else {
              //  console.log("response error", response.data.message);
              return Promise.reject({
                ...response.data.message,
              });
            }
          }
        })
        .catch((error) => {
          return Promise.reject({
            ...error,
          });
        });
    }
  );  




