import { createSlice } from "@reduxjs/toolkit";

import {
  addCustomerDocumentAction,
  addCustomerNoteAction,
  addPaymentMethodAction,
  checkPaymentMethodExistAction,
  customerActiveInactiveAction,
  deleteCustomerDocumentsAction,
  deleteCustomerNoteAction,
  getAllCustomerAction,
  getCustomerAction, getCustomerDocumentsAction, getCustomerNotesAction, getPaymentMethods, insertUpdateCustomerAction
} from "../../actions/customer/customerActions";

import { toast } from 'react-toastify';

const INITIAL_STATE = {
  customer: {
    data: {
      data: [],
      totalCount: 0,
      groupCount: -1,
      summary: null
    },
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },
  selectedCustomer: {
    data: {},
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },

  insertUpdateDetails: {
    data: {},
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
    newRecord: false
  },
  customerActiveInactiveDetails: {
    data: {},

    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },
  paymentMethod: {
    data: {
      records: [],
      totalRecords: 0,
    },
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },
  paymentMethodExistData: {
    data: {},
    apiMsg: {
      message: null,
      status: null,
      type: null,
    }
  },
  paymentMethodUpdateDetails: {
    data: {},
    apiMsg: {
      message: null,
      status: null,
      type: null,
    }
  },
  customerDocuments: {
    data: {
      records: [],
      totalRecords: 0,
    },
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },
  customerDocumentInsertDetails: {
    data: {},
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },
  customerDocumentRemoveDetails: {
    data: null,
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },
  customerNotes: {
    data: {
      records: [],
      totalRecords: 0,
    },
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },
  customerNoteInsertDetails: {
    data: {},
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },
  customerNoteRemoveDetails: {
    data: null,
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  }
};

const customerReducer = createSlice({
  name: "Customer",
  initialState: INITIAL_STATE,
  reducers: {
    RESET(state, action) {
      state = INITIAL_STATE;
    },
    ResetCustomerDetailsState(state, action) {
      state.insertUpdateDetails = INITIAL_STATE.insertUpdateDetails;
      state.selectedCustomer = INITIAL_STATE.selectedCustomer;
      state.customerDocumentInsertDetails = INITIAL_STATE.customerDocumentInsertDetails;
      state.customerDocuments = INITIAL_STATE.customerDocuments;
      state.customerNoteInsertDetails = INITIAL_STATE.customerNoteInsertDetails;
      state.customerNotes = INITIAL_STATE.customerNotes;
      state.customerActiveInactiveDetails = INITIAL_STATE.customerActiveInactiveDetails;
    },
    ResetDocumentUploadState(state, action) {
      state.customerDocumentInsertDetails = INITIAL_STATE.customerDocumentInsertDetails;
    },
    ResetDocumentRemoveState(state, action) {
      state.customerDocumentRemoveDetails = INITIAL_STATE.customerDocumentRemoveDetails;
    },
    ResetNoteUploadState(state, action) {
      state.customerNoteInsertDetails = INITIAL_STATE.customerNoteInsertDetails;
    },
    ResetNoteRemoveState(state, action) {
      state.customerNoteRemoveDetails = INITIAL_STATE.customerNoteRemoveDetails;

    }
  },
  extraReducers: (builder) => {

    //getallcustomeraction start


    builder.addCase(getAllCustomerAction.fulfilled, (state, action) => {
      state.customer.data.data = action.payload.data;
      state.customer.data.totalCount = action.payload.totalCount;
      state.customer.data.groupCount = action.payload.groupCount;
      state.customer.data.summary = action.payload.summary;
      state.customer.apiMsg.status = action.meta.requestStatus;
      //state.customer.apiMsg.message = action.payload.message.message;
    });
    builder.addCase(getAllCustomerAction.pending, (state, action) => {
      state.customer.data.records = null;
      //state.customer.apiMsg.message = action.meta.requestStatus;;
      state.customer.apiMsg.status = action.meta.requestStatus;
    });
    builder.addCase(getAllCustomerAction.rejected, (state, action) => {
      //state.customer.apiMsg.message = action.error.message;
      state.customer.apiMsg.status = action.meta.requestStatus;
    });
    //getallcustomeraction end

    //getcustomeraction start

    builder.addCase(getCustomerAction.fulfilled, (state, action) => {
      state.selectedCustomer.data = action.payload.data;
      state.selectedCustomer.apiMsg.status = action.meta.requestStatus;
      state.selectedCustomer.apiMsg.message = action.payload.message.message;
    });
    builder.addCase(getCustomerAction.pending, (state, action) => {
      //console.log("getCustomerAction pending", action);
      state.selectedCustomer.data = null;
      state.selectedCustomer.apiMsg.message = action.meta.requestStatus;;
      state.selectedCustomer.apiMsg.status = action.meta.requestStatus;
    });
    builder.addCase(getCustomerAction.rejected, (state, action) => {
      //console.log("getCustomerAction rejected", action);
      state.selectedCustomer.apiMsg.message = action.error.message;
      state.selectedCustomer.apiMsg.status = action.meta.requestStatus;
    });

    // getcustomeraction end


    // insert update insertUpdateCustomerAction Action start

    builder.addCase(
      insertUpdateCustomerAction.fulfilled,
      (state, action) => {
        //console.log("insertUpdateCustomerAction", action);

        state.insertUpdateDetails.data = action.payload.data.data;
        state.insertUpdateDetails.apiMsg.status = action.meta.requestStatus;
        state.insertUpdateDetails.apiMsg.message = action.payload.message.message;

        if (!action.payload.newRecord) {
          let updatedRecord = state.customer.data.data.map(x => {
            if (x.id == action.payload.data.data.id) {
              return action.payload.data.data;
            }
            else {
              return x;
            }
          })

          state.customer.data.data = updatedRecord;

        }
        else {
          if (state.customer.data.data.length) {
            state.customer.data.data.unshift(action.payload.data.data);
          }
          else {
            state.customer.data.data.push(action.payload.data.data);
          }

        }
        toast.success(action.payload.message.message, {
          position: toast.POSITION.BOTTOM_CENTER,
          toastId: "dept-error"
        });

      }
    );
    builder.addCase(
      insertUpdateCustomerAction.pending,
      (state, action) => {
        //console.log("insertUpdateCustomerAction pending", action);
        state.insertUpdateDetails.apiMsg.status = action.meta.requestStatus;
      }
    );
    builder.addCase(
      insertUpdateCustomerAction.rejected,
      (state, action) => {
        //   console.log("insertUpdateVatAction rejected", action);
        //   console.log(action.error.message);
        state.insertUpdateDetails.apiMsg.message = action.error.message;
        state.insertUpdateDetails.apiMsg.status = action.meta.requestStatus;

        toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
          position: toast.POSITION.BOTTOM_CENTER,
          toastId: "dept-error"
        });
      }
    );


    //   insert update insertUpdateDetails action end



    // activeinactive customer action start

    builder.addCase(
      customerActiveInactiveAction.fulfilled,
      (state, action) => {

        state.customerActiveInactiveDetails.data = action.payload.data;
        state.customerActiveInactiveDetails.apiMsg.status = action.meta.requestStatus;
        state.customerActiveInactiveDetails.apiMsg.message = action.payload.message.message;

        let updatedRecord = state.customer.data.data.map(x => {

          if (x.id == action.meta.arg.id) {
            x.isActive = action.meta.arg.isActive;
            return x;
          }
          else {
            return x;
          }
        })



        state.customer.data.data = updatedRecord;

        toast.success(action.payload.message.message, {
          position: toast.POSITION.BOTTOM_CENTER,
          toastId: "success"
        });

      }
    );
    builder.addCase(
      customerActiveInactiveAction.pending,
      (state, action) => {
        state.customerActiveInactiveDetails.apiMsg.status = action.meta.requestStatus;
      }
    );
    builder.addCase(
      customerActiveInactiveAction.rejected,
      (state, action) => {
        state.customerActiveInactiveDetails.apiMsg.message = action.error.message;
        state.customerActiveInactiveDetails.apiMsg.status = action.meta.requestStatus;

        toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
          position: toast.POSITION.BOTTOM_CENTER,
          toastId: "error"
        });
      }
    );

    // activeinactive customer action end


    // getallpaymentmethod start


    builder.addCase(getPaymentMethods.fulfilled, (state, action) => {
      state.paymentMethod.data.records = action.payload.data;
      state.paymentMethod.apiMsg.status = action.meta.requestStatus;
      state.paymentMethod.apiMsg.message = action.payload.message.message;
    });
    builder.addCase(getPaymentMethods.pending, (state, action) => {
      state.paymentMethod.data.records = null;
      state.paymentMethod.apiMsg.message = action.meta.requestStatus;;
      state.paymentMethod.apiMsg.status = action.meta.requestStatus;
    });
    builder.addCase(getPaymentMethods.rejected, (state, action) => {
      state.paymentMethod.apiMsg.message = action.error.message;
      state.paymentMethod.apiMsg.status = action.meta.requestStatus;
    });


    // getallpaymentmethod end


    // paymentmethodupdateaction start
    builder.addCase(addPaymentMethodAction.fulfilled, (state, action) => {
      state.paymentMethodUpdateDetails.data = action.payload.data;
      state.paymentMethodUpdateDetails.apiMsg.status = action.meta.requestStatus;
      state.paymentMethodUpdateDetails.apiMsg.message = action.payload.message.message;
      if (state.paymentMethod.data.records.length) {
        state.paymentMethod.data.records.unshift(action.payload.data);
      }
      else {
        state.paymentMethod.data.records.push(action.payload.data);
      }

      toast.success(action.payload.message.message, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    });
    builder.addCase(addPaymentMethodAction.pending, (state, action) => {
      state.paymentMethodUpdateDetails.data = null;
      state.paymentMethodUpdateDetails.apiMsg.message = action.meta.requestStatus;;
      state.paymentMethodUpdateDetails.apiMsg.status = action.meta.requestStatus;
    });
    builder.addCase(addPaymentMethodAction.rejected, (state, action) => {
      state.paymentMethodUpdateDetails.apiMsg.message = action.error.message;
      state.paymentMethodUpdateDetails.apiMsg.status = action.meta.requestStatus;
    });


    // paymentmethodupdateaction end


    // paymentMethodExistorNot check start

    builder.addCase(checkPaymentMethodExistAction.fulfilled, (state, action) => {

      state.paymentMethodExistData.data = action.payload.data;
      state.paymentMethodExistData.apiMsg.status = action.meta.requestStatus;
      state.paymentMethodExistData.apiMsg.message = action.payload.message.message;

    });
    builder.addCase(checkPaymentMethodExistAction.pending, (state, action) => {
      state.paymentMethodExistData.data = null;
      state.paymentMethodExistData.apiMsg.message = action.meta.requestStatus;;
      state.paymentMethodExistData.apiMsg.status = action.meta.requestStatus;

    });
    builder.addCase(checkPaymentMethodExistAction.rejected, (state, action) => {
      state.paymentMethodExistData.apiMsg.message = action.error.message;
      state.paymentMethodExistData.apiMsg.status = action.meta.requestStatus;
      toast.error("Something went wrong please try again", {
        position: toast.POSITION.BOTTOM_CENTER,

      });
    });

    // paymentMethodExistorNot check end

    // getcustomerDocuments start

    builder.addCase(getCustomerDocumentsAction.fulfilled, (state, action) => {
      state.customerDocuments.data.records = action.payload.data;
      state.customerDocuments.apiMsg.status = action.meta.requestStatus;
      state.customerDocuments.apiMsg.message = action.payload.message.message;
    });
    builder.addCase(getCustomerDocumentsAction.pending, (state, action) => {
      state.customerDocuments.data.records = null;
      state.customerDocuments.apiMsg.message = action.meta.requestStatus;;
      state.customerDocuments.apiMsg.status = action.meta.requestStatus;
    });
    builder.addCase(getCustomerDocumentsAction.rejected, (state, action) => {
      state.customerDocuments.apiMsg.message = action.error.message;
      state.customerDocuments.apiMsg.status = action.meta.requestStatus;
    });

    // getcustomerDocuments end

    // removecustomerDocument start
    builder.addCase(deleteCustomerDocumentsAction.fulfilled, (state, action) => {
      state.customerDocumentRemoveDetails.data = action.payload.data;
      state.customerDocumentRemoveDetails.apiMsg.status = action.meta.requestStatus;
      state.customerDocumentRemoveDetails.apiMsg.message = action.payload.message.message;
      state.customerDocuments.data.records = state.customerDocuments.data.records.filter(doc => doc.id !== action.meta.arg.id)

      toast.success(action.payload.message.message, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    });
    builder.addCase(deleteCustomerDocumentsAction.pending, (state, action) => {
      state.customerDocumentRemoveDetails.data = null;
      state.customerDocumentRemoveDetails.apiMsg.message = action.meta.requestStatus;;
      state.customerDocumentRemoveDetails.apiMsg.status = action.meta.requestStatus;
    });
    builder.addCase(deleteCustomerDocumentsAction.rejected, (state, action) => {
      state.customerDocumentRemoveDetails.apiMsg.message = action.error.message;
      state.customerDocumentRemoveDetails.apiMsg.status = action.meta.requestStatus;
    });

    // removecustomerDocument end

    // addcustomerdocument start

    builder.addCase(addCustomerDocumentAction.fulfilled, (state, action) => {
      state.customerDocumentInsertDetails.data = action.payload.data;
      state.customerDocumentInsertDetails.apiMsg.status = action.meta.requestStatus;
      state.customerDocumentInsertDetails.apiMsg.message = action.payload.message.message;

      if (state.customerDocuments.data.records.length) {
        state.customerDocuments.data.records.unshift(action.payload.data);
      }
      else {
        state.customerDocuments.data.records.push(action.payload.data);
      }

      toast.success(action.payload.message.message, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    });
    builder.addCase(addCustomerDocumentAction.pending, (state, action) => {
      state.customerDocumentInsertDetails.data = null;
      state.customerDocumentInsertDetails.apiMsg.message = action.meta.requestStatus;;
      state.customerDocumentInsertDetails.apiMsg.status = action.meta.requestStatus;
    });
    builder.addCase(addCustomerDocumentAction.rejected, (state, action) => {
      state.customerDocumentInsertDetails.apiMsg.message = action.error.message;
      state.customerDocumentInsertDetails.apiMsg.status = action.meta.requestStatus;
      toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
        position: toast.POSITION.BOTTOM_CENTER,

      });
    });

    // addcustomerdocument end


    // getcustomerNotes start

    builder.addCase(getCustomerNotesAction.fulfilled, (state, action) => {
      state.customerNotes.data.records = action.payload.data;
      state.customerNotes.apiMsg.status = action.meta.requestStatus;
      state.customerNotes.apiMsg.message = action.payload.message.message;
    });
    builder.addCase(getCustomerNotesAction.pending, (state, action) => {
      state.customerNotes.data.records = null;
      state.customerNotes.apiMsg.message = action.meta.requestStatus;;
      state.customerNotes.apiMsg.status = action.meta.requestStatus;
    });
    builder.addCase(getCustomerNotesAction.rejected, (state, action) => {
      state.customerNotes.apiMsg.message = action.error.message;
      state.customerNotes.apiMsg.status = action.meta.requestStatus;
    });

    // getcustomerNotes end

    // removecustomerNote start

    builder.addCase(deleteCustomerNoteAction.fulfilled, (state, action) => {
      state.customerNoteRemoveDetails.data = action.payload.data;
      state.customerNoteRemoveDetails.apiMsg.status = action.meta.requestStatus;
      state.customerNoteRemoveDetails.apiMsg.message = action.payload.message.message;
      state.customerNotes.data.records = state.customerNotes.data.records.filter(note => note.id !== action.meta.arg.id)

      toast.success(action.payload.message.message, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    });
    builder.addCase(deleteCustomerNoteAction.pending, (state, action) => {
      state.customerNoteRemoveDetails.data = null;
      state.customerNoteRemoveDetails.apiMsg.message = action.meta.requestStatus;;
      state.customerNoteRemoveDetails.apiMsg.status = action.meta.requestStatus;
    });
    builder.addCase(deleteCustomerNoteAction.rejected, (state, action) => {
      state.customerNoteRemoveDetails.apiMsg.message = action.error.message;
      state.customerNoteRemoveDetails.apiMsg.status = action.meta.requestStatus;
    });

    // removecustomerNote end

    // addcustomernote start

    builder.addCase(addCustomerNoteAction.fulfilled, (state, action) => {
      state.customerNoteInsertDetails.data = action.payload.data;
      state.customerNoteInsertDetails.apiMsg.status = action.meta.requestStatus;
      state.customerNoteInsertDetails.apiMsg.message = action.payload.message.message;

      if (state.customerNotes.data.records.length) {
        state.customerNotes.data.records.unshift(action.payload.data);
      }
      else {
        state.customerNotes.data.records.push(action.payload.data);
      }

      toast.success(action.payload.message.message, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    });
    builder.addCase(addCustomerNoteAction.pending, (state, action) => {
      state.customerNoteInsertDetails.data = null;
      state.customerNoteInsertDetails.apiMsg.message = action.meta.requestStatus;;
      state.customerNoteInsertDetails.apiMsg.status = action.meta.requestStatus;
    });
    builder.addCase(addCustomerNoteAction.rejected, (state, action) => {
      state.customerNoteInsertDetails.apiMsg.message = action.error.message;
      state.customerNoteInsertDetails.apiMsg.status = action.meta.requestStatus;
      toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
        position: toast.POSITION.BOTTOM_CENTER,

      });
    });

    // addcustomernote end


  },
});
export const { RESET, ResetCustomerDetailsState, ResetDocumentUploadState, ResetNoteUploadState, ResetDocumentRemoveState, ResetNoteRemoveState } = customerReducer.actions;
export default customerReducer.reducer;

