import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiCall, apiFileUploadCall } from "../../../API";

export const getAllSupplierExpensesInvoicesAction = createAsyncThunk(
  "SupplierExpensesinvoices/GetAllSupplierExpensesInvoices",
  async (data) => {
    return apiCall(`Expense`, "GET", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {

            return {
              ...response.data,
            }
          } else {
            //  console.log("response error", response.data.message);
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);

export const getSupplierExpenseAction = createAsyncThunk(
  "SupplierExpensesinvoices/getSupplierExpense",
  async (data) => {
    return apiCall("Expense/GetExpense", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            return {
              ...response.data,
            }
          } else {
            //  console.log("response error", response.data.message);
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);



export const addSupplierExpensesDocumentAction = createAsyncThunk(
  "SupplierExpensesinvoices/addSupplierExpensesDocument",
  async (data) => {
    return apiFileUploadCall("Expense/AddExpenseDocument", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            return {
              ...response.data,
            }
          } else {
            //  console.log("response error", response.data.message);
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);

export const getSupplierExpensesDocumentsAction = createAsyncThunk(
  "SupplierExpensesinvoices/getSupplierExpensesDocuments",
  async (data) => {
    return apiCall("Expense/GetExpenseDocuments", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            return {
              ...response.data,
            }
          } else {
            //  console.log("response error", response.data.message);
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);


export const deleteSupplierExpensesDocumentsAction = createAsyncThunk(
  "SupplierExpensesinvoices/removeSupplierExpensesDocuments",
  async (data) => {
    return apiCall("Expense/RemoveExpenseDocument", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {

            return {
              ...response.data,
            }
          } else {
            //  console.log("response error", response.data.message);
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);



export const insertUpdateSupplierExpensesAction = createAsyncThunk(
  "SupplierExpensesinvoices/insertupdateSupplierExpensesinvoices",
  async (data) => {
    return apiCall("Expense/Add", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            // console.log('response.data ',response.data.message)
            let message = response.data.message
            // console.log("message",message);
            return {
              data: {
                ...response.data,
              },
              message: {
                code: response.data.message.code,
                type: response.data.message.type,
                message: response.data.message.message
              },
              newRecord: data.id ? false : true
            }
          } else {
            // console.log("response error", response.data.message);
            if (!response.data.message && response.data.errors && response.data.errors.length) {
              response.data.message = response.data.errors[0];
            }
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
        else {
          return Promise.reject({
            ...response.data,
          });
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);

export const getPaymentMethods = createAsyncThunk(
  "SupplierExpensesinvoices/GetAllPaymentMethods",
  async (data) => {
    return apiCall("paymentmethod", "GET", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {

            return {
              ...response.data,
            }
          } else {
            //  console.log("response error", response.data.message);
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);

export const getAllSupplierAction = createAsyncThunk(
  "SupplierExpensesinvoices/GetAllSupplier",
  async (data) => {
    return apiCall(`supplier`, "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {

            return {
              ...response.data,
            }
          } else {
            //  console.log("response error", response.data.message);
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);


export const getAllProductsAction = createAsyncThunk(
  "SupplierExpensesinvoices/getAllProducts",
  async (data) => {
    return apiCall("product/popupproduct", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            return {
              ...response.data,
            }
          } else {
            //  console.log("response error", response.data.message);
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);
