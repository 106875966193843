import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiCall, apiFileUploadCall } from "../../../API";

export const getAllSupplierStatementsAction = createAsyncThunk(
  "SupplierStatements/GetAllSupplierStatements",
  async (data) => {
    return apiCall(`SupplierStatement`, "GET", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {

            return {
              ...response.data,
            }
          } else {
            //  console.log("response error", response.data.message);
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);

export const getSupplierStatementsAction = createAsyncThunk(
  "SupplierStatements/getSupplierStatements",
  async (data) => {
    return apiCall("SupplierStatement/GetSuppliers", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            return {
              ...response.data,
            }
          } else {
            //  console.log("response error", response.data.message);
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);

export const insertUpdateSupplierStatementAction = createAsyncThunk(
  "SupplierStatements/AddSupplierStatements",
  async (data) => {
      return apiCall("supplierstatement/add", "POST", data, false)
          .then((response) => {
              if (response.status === 200) {
                  if (!response.data.hasError) {
                      return {
                          ...response.data,
                      }
                  } else {
                      return Promise.reject({
                          ...response.data.message,
                      });
                  }
              }
          })
          .catch((error) => {
              return Promise.reject({
                  ...error,
              });
          });
  }
);

export const getSupplierStatementFileAction = createAsyncThunk(
  "SupplierStatements/getSupplierStatementFile",
  async (data) => {
    return apiCall("SupplierStatement/GetSupplierStatement", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            return {
              ...response.data,
            }
          } else {
            //  console.log("response error", response.data.message);
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);