import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiCall, apiFileUploadCall } from "../../../API";

export const getAllQuotationAction = createAsyncThunk(
    "Quotation/GetAllQuotation",
    async (data) => {
        const { filterData, query } = data;
        return apiCall(`customerinvoice/getallquotation${query ? query : ""}`, "POST", filterData, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {

                        return {
                            ...response.data,
                        }
                    } else {
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);

export const getQuotationAction = createAsyncThunk(
    "Quotation/GetQuotation",
    async (data) => {
        return apiCall("customerinvoice/getcustomerinvoice", "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {

                        return {
                            ...response.data,
                        }
                    } else {
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);


export const insertUpdateQuotationAction = createAsyncThunk(
    "Quotation/insertupdateQuotation",
    async (data) => {
        return apiCall("customerinvoice/addquotation", "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        let message = response.data.message
                        return {
                            data: {
                                ...response.data,
                            },
                            message: {
                                code: response.data.message.code,
                                type: response.data.message.type,
                                message: response.data.message.message
                            },
                            newRecord: data.id ? false : true
                        }
                    } else {
                        if (!response.data.message && response.data.errors && response.data.errors.length) {
                            response.data.message = response.data.errors[0];
                        }
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
                else {
                    return Promise.reject({
                        ...response.data,
                    });
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);

export const quotationActiveInactiveAction = createAsyncThunk(
    'Quotation/activeinactive',
    async (data) => {
        return apiCall("customerinvoice/activeinactive", "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        return {
                            ...response.data,
                        }
                    } else {
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
)

export const addQuotationDocumentAction = createAsyncThunk(
    "Quotation/addQuotationDocument",
    async (data) => {
        return apiFileUploadCall("customerinvoice/addcustomerinvoicedocument", "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        return {
                            ...response.data,
                        }
                    } else {
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);

export const getQuotationDocumentsAction = createAsyncThunk(
    "Quotation/getQuotationDocuments",
    async (data) => {
        return apiCall("customerinvoice/getCustomerInvoiceDocuments", "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        return {
                            ...response.data,
                        }
                    } else {
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);


export const deleteQuotationDocumentsAction = createAsyncThunk(
    "Quotation/removeQuotationDocuments",
    async (data) => {
        return apiCall("customerinvoice/removeCustomerInvoiceDocument", "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {

                        return {
                            ...response.data,
                        }
                    } else {
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);

export const getAllProductsAction = createAsyncThunk(
    "Quotation/getAllProducts",
    async (data) => {
        return apiCall(`customerInvoice/getproductforcustomerinvoice${data.query ? data.query : ""}`, "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        return {
                            ...response.data,
                        }
                    } else {
                        //  console.log("response error", response.data.message);
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);

export const getAllInvoiceProductsAction = createAsyncThunk(
    "Quotation/getAllInvoiceProductsAction",
    async (data) => {
        return apiCall(`customerInvoice/getproductforcustomerinvoice${data.query ? data.query : ""}`, "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        return {
                            ...response.data,
                        }
                    } else {
                        //  console.log("response error", response.data.message);
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);

export const convertQuotationToInvoiceAction = createAsyncThunk(
    "Quotation/convertQuotationToInvoice",
    async (data) => {
        return apiCall("customerinvoice/convertinvoice", "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {

                        return {
                            ...response.data,
                        }
                    } else {
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);

export const deleteQuotationAction = createAsyncThunk(
    "Quotation/deleteInvoice",
    async (data) => {
        return apiCall("customerinvoice/deleteinvoice", "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {

                        return {
                            ...response.data,
                        }
                    } else {
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);











