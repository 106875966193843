import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiCall } from '../../../../API';
import { toast } from 'react-toastify';

export const getAllStockSupplierReturnAction = createAsyncThunk(
    'Reports/getAllloStockSupplierReturn',
    async (data) => {
        return apiCall(`StockReturn/GetCompletedStockReturns${data.query ? data.query : ""}`, 'POST', data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {

                        return {
                            ...response.data,

                        };
                    } else {
                        // toast.error(response.data.message.message);
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                // toast.error(error);
                return Promise.reject({
                    ...error,
                });
            });

    }
)

export const getStockReturnTosupplierReportAction = createAsyncThunk(
    "StockReturnToSupplierReport/GetStockReturnToSupplierReport",
    async (data) => {
      return apiCall("stockreturn/getstockreturn", "POST", data, false)
        .then((response) => {
          if (response.status === 200) {
            if (!response.data.hasError) {
              return {
                ...response.data,
              }
            } else {
              return Promise.reject({
                ...response.data.message,
              });
            }
          }
        })
        .catch((error) => {
          return Promise.reject({
            ...error,
          });
        });
    }
  );
