import { createSlice } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import { getAllStockSupplierReturnAction, getStockReturnTosupplierReportAction } from "../../../actions/reports/stock-reports/stockSupplierReturnAction";

const INITIAL_STATE = {
    stockSupplierReturn: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    selectedStockSupplierReturn: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
};

const stockSupplierReturnReducer = createSlice({
    name: "Reports",
    initialState: INITIAL_STATE,
    reducers: {
        RESET(state, action) {
            state.stockSupplierReturn = INITIAL_STATE.stockSupplierReturn;
        },
        ResetStockSupplierReturnDetailsState(state, action) {
            state.selectedStockSupplierReturn = INITIAL_STATE.selectedStockSupplierReturn;
        }

    },
    extraReducers: (builder) => {
        //------------------------------- getAllStockSupplierReturnAction start -------------------------
        // eslint-disable-next-line
        builder.addCase(getAllStockSupplierReturnAction.fulfilled, (state, action) => {
            state.stockSupplierReturn.data = action.payload.data;
            state.stockSupplierReturn.apiMsg.status = action.meta.requestStatus;
            // state.stockSupplierReturn.apiMsg.message = action.payload.message.message;
        }),
            builder.addCase(getAllStockSupplierReturnAction.pending, (state, action) => {
                //console.log("getAllStockSupplierReturnAction pending", action);
                state.stockSupplierReturn.apiMsg.status = action.meta.requestStatus;
            }),
            builder.addCase(getAllStockSupplierReturnAction.rejected, (state, action) => {
                //console.log("getAllStockSupplierReturnAction rejected", action);
                state.stockSupplierReturn.apiMsg.message = action.error.message;
                state.stockSupplierReturn.apiMsg.status = action.meta.requestStatus;
            })
        //------------------------------- getAllSalesSummaryReportACTION end -------------------------

                //------------ getStockReturnTosupplierReportAction -----------

                builder.addCase(getStockReturnTosupplierReportAction.fulfilled, (state, action) => {

                    state.selectedStockSupplierReturn.data = action.payload.data;
                    state.selectedStockSupplierReturn.apiMsg.status = action.meta.requestStatus;
                    state.selectedStockSupplierReturn.apiMsg.message = action.payload.message.message;
                });
        
                builder.addCase(getStockReturnTosupplierReportAction.pending, (state, action) => {
                    //console.log("getStockReturnTosupplierReportAction pending", action);
                    state.selectedStockSupplierReturn.data = null;
                    state.selectedStockSupplierReturn.apiMsg.message = action.meta.requestStatus;;
                    state.selectedStockSupplierReturn.apiMsg.status = action.meta.requestStatus;
                });
        
                builder.addCase(getStockReturnTosupplierReportAction.rejected, (state, action) => {
                    // console.log("getStockReturnTosupplierReportAction rejected", action);
                    state.selectedStockSupplierReturn.apiMsg.message = action.error.message;
                    state.selectedStockSupplierReturn.apiMsg.status = action.meta.requestStatus;
                });
        
        
                //   get manualPriceProduct end
    }
});

export const { RESET, ResetStockSupplierReturnDetailsState } = stockSupplierReturnReducer.actions;
export default stockSupplierReturnReducer.reducer;
