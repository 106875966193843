import { createSlice } from "@reduxjs/toolkit";

// import { getAllProformaInvoicesAction, getProformaInvoiceAction, insertUpdateProformaInvoiceAction, getProformaInvoiceDocumentsAction, addProformaInvoiceDocumentAction, deleteProformaInvoiceDocumentsAction, proformaInvoiceActiveInactiveAction, getAllProductsAction, getAllInvoiceProductsAction } from "../../actions/customer/customerInvoiceActions";

import { getAllProformaInvoicesAction,deleteInvoiceAction, getProformaInvoiceAction, insertUpdateProformaInvoiceAction, getProformaInvoiceDocumentsAction, addProformaInvoiceDocumentAction, deleteProformaInvoiceDocumentsAction, proformaInvoiceActiveInactiveAction, getAllProductsAction, getAllInvoiceProductsAction, convertProformaToInvoiceAction } from "../../actions/customer/proformaInvoiceActions";

import { toast } from 'react-toastify';


const INITIAL_STATE = {
    proformaInvoices: {
        data: {
            records: [],
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    selectedProformaInvoice: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },

    insertUpdateProformaInvoiceDetails: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },
    proformaInvoiceActiveInactiveDetails: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },

    proformaInvoiceDocuments: {
        data: {
            records: [],
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    proformaInvoiceDocumentInsertDetails: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    proformaInvoiceDocumentRemoveDetails: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    barcodeProductList: {
        data: {
            records: [],
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    invoiceProductList: {
        data: {
            records: [],
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    convertToInvoiceDetails: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    deleteProformaInvoiceDetails: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    }

};

const proformaInvoicesReducer = createSlice({
    name: "proformaInvoicesReducer",
    initialState: INITIAL_STATE,
    reducers: {
        RESET(state, action) {
            state = INITIAL_STATE;
        },
        ResetProformaInvoiceDetailsState(state, action) {
            state.insertUpdateProformaInvoiceDetails = INITIAL_STATE.insertUpdateProformaInvoiceDetails;
            state.selectedProformaInvoice = INITIAL_STATE.selectedProformaInvoice;
            state.proformaInvoiceDocumentInsertDetails = INITIAL_STATE.proformaInvoiceDocumentInsertDetails;
            state.proformaInvoiceDocuments = INITIAL_STATE.proformaInvoiceDocuments;
            state.proformaInvoiceActiveInactiveDetails = INITIAL_STATE.proformaInvoiceActiveInactiveDetails;
            state.convertToInvoiceDetails = INITIAL_STATE.convertToInvoiceDetails;
        },
        ResetInvoiceDocumentUploadState(state, action) {
            state.proformaInvoiceDocumentInsertDetails = INITIAL_STATE.proformaInvoiceDocumentInsertDetails;
        },
        ResetInvoiceDocumentRemoveState(state, action) {
            state.proformaInvoiceDocumentRemoveDetails = INITIAL_STATE.proformaInvoiceDocumentRemoveDetails;
        },
        ResetBarcodeProductsList(state, action) {
            state.barcodeProductList = INITIAL_STATE.barcodeProductList;
            state.invoiceProductList = INITIAL_STATE.invoiceProductList;
        },
        ResetDeleteDetailsState(state, action) {
            state.deleteProformaInvoiceDetails = INITIAL_STATE.deleteProformaInvoiceDetails
        }

    },
    extraReducers: (builder) => {

        //getallcustomeraction start


        builder.addCase(getAllProformaInvoicesAction.fulfilled, (state, action) => {
            state.proformaInvoices.data.records = action.payload.data;
            state.proformaInvoices.apiMsg.status = action.meta.requestStatus;
            //state.customer.apiMsg.message = action.payload.message.message;
        });
        builder.addCase(getAllProformaInvoicesAction.pending, (state, action) => {
            state.proformaInvoices.data.records = null;
            //state.customer.apiMsg.message = action.meta.requestStatus;;
            state.proformaInvoices.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllProformaInvoicesAction.rejected, (state, action) => {
            //state.customer.apiMsg.message = action.error.message;
            state.proformaInvoices.apiMsg.status = action.meta.requestStatus;
        });
        //getallcustomeraction end

        //getcustomeraction start

        builder.addCase(getProformaInvoiceAction.fulfilled, (state, action) => {
            state.selectedProformaInvoice.data = action.payload.data;
            state.selectedProformaInvoice.apiMsg.status = action.meta.requestStatus;
            state.selectedProformaInvoice.apiMsg.message = action.payload.message.message;
        });
        builder.addCase(getProformaInvoiceAction.pending, (state, action) => {
            //console.log("getCustomerAction pending", action);
            state.selectedProformaInvoice.data = null;
            state.selectedProformaInvoice.apiMsg.message = action.meta.requestStatus;;
            state.selectedProformaInvoice.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getProformaInvoiceAction.rejected, (state, action) => {
            //console.log("getCustomerAction rejected", action);
            state.selectedProformaInvoice.apiMsg.message = action.error.message;
            state.selectedProformaInvoice.apiMsg.status = action.meta.requestStatus;
        });

        // getcustomeraction end


        // insert update insertUpdateCustomerAction Action start

        builder.addCase(
            insertUpdateProformaInvoiceAction.fulfilled,
            (state, action) => {
                //console.log("insertUpdateCustomerAction", action);

                state.insertUpdateProformaInvoiceDetails.data = action.payload.data.data;
                state.insertUpdateProformaInvoiceDetails.apiMsg.status = action.meta.requestStatus;
                state.insertUpdateProformaInvoiceDetails.apiMsg.message = action.payload.message.message;

                if (!action.payload.newRecord) {
                    let updatedRecord = state.proformaInvoices.data.records.map(x => {
                        if (x.id == action.payload.data.data.id) {
                            return action.payload.data.data;
                        }
                        else {
                            return x;
                        }
                    })

                    state.proformaInvoices.data.records = updatedRecord;
                }
                else {
                    if (state.proformaInvoices.data.records.length) {
                        state.proformaInvoices.data.records.unshift(action.payload.data.data);
                    }
                    else {
                        state.proformaInvoices.data.records.push(action.payload.data.data);
                    }
                }
                toast.success(action.payload.message.message, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "dept-error"
                });

            }
        );
        builder.addCase(
            insertUpdateProformaInvoiceAction.pending,
            (state, action) => {
                //console.log("insertUpdateCustomerAction pending", action);
                state.insertUpdateProformaInvoiceDetails.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            insertUpdateProformaInvoiceAction.rejected,
            (state, action) => {
                //   console.log("insertUpdateVatAction rejected", action);
                //   console.log(action.error.message);
                state.insertUpdateProformaInvoiceDetails.apiMsg.message = action.error.message;
                state.insertUpdateProformaInvoiceDetails.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "dept-error"
                });
            }
        );


        //   insert update insertUpdateDetails action end



        // activeinactive customer action start

        builder.addCase(
            proformaInvoiceActiveInactiveAction.fulfilled,
            (state, action) => {

                state.proformaInvoiceActiveInactiveDetails.data = action.payload.data;
                state.proformaInvoiceActiveInactiveDetails.apiMsg.status = action.meta.requestStatus;
                state.proformaInvoiceActiveInactiveDetails.apiMsg.message = action.payload.message.message;

                let updatedRecord = state.proformaInvoices.data.records.map(x => {

                    if (x.id == action.meta.arg.id) {
                        x.isActive = action.meta.arg.isActive;
                        return x;
                    }
                    else {
                        return x;
                    }
                })



                state.proformaInvoices.data.records = updatedRecord;

                toast.success(action.payload.message.message, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "success"
                });

            }
        );
        builder.addCase(
            proformaInvoiceActiveInactiveAction.pending,
            (state, action) => {
                state.proformaInvoiceActiveInactiveDetails.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            proformaInvoiceActiveInactiveAction.rejected,
            (state, action) => {
                state.proformaInvoiceActiveInactiveDetails.apiMsg.message = action.error.message;
                state.proformaInvoiceActiveInactiveDetails.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "error"
                });
            }
        );

        // activeinactive customer action end



        // getcustomerDocuments start

        builder.addCase(getProformaInvoiceDocumentsAction.fulfilled, (state, action) => {
            state.proformaInvoiceDocuments.data.records = action.payload.data;
            state.proformaInvoiceDocuments.apiMsg.status = action.meta.requestStatus;
            state.proformaInvoiceDocuments.apiMsg.message = action.payload.message.message;
        });
        builder.addCase(getProformaInvoiceDocumentsAction.pending, (state, action) => {
            state.proformaInvoiceDocuments.data.records = null;
            state.proformaInvoiceDocuments.apiMsg.message = action.meta.requestStatus;;
            state.proformaInvoiceDocuments.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getProformaInvoiceDocumentsAction.rejected, (state, action) => {
            state.proformaInvoiceDocuments.apiMsg.message = action.error.message;
            state.proformaInvoiceDocuments.apiMsg.status = action.meta.requestStatus;
        });

        // getcustomerDocuments end

        // removecustomerDocument start
        builder.addCase(deleteProformaInvoiceDocumentsAction.fulfilled, (state, action) => {
            state.proformaInvoiceDocumentRemoveDetails.data = action.payload.data;
            state.proformaInvoiceDocumentRemoveDetails.apiMsg.status = action.meta.requestStatus;
            state.proformaInvoiceDocumentRemoveDetails.apiMsg.message = action.payload.message.message;
            state.proformaInvoiceDocuments.data.records = state.proformaInvoiceDocuments.data.records.filter(doc => doc.id !== action.meta.arg.id)

            toast.success(action.payload.message.message, {
                position: toast.POSITION.BOTTOM_CENTER,
            });
        });
        builder.addCase(deleteProformaInvoiceDocumentsAction.pending, (state, action) => {
            state.proformaInvoiceDocumentRemoveDetails.data = null;
            state.proformaInvoiceDocumentRemoveDetails.apiMsg.message = action.meta.requestStatus;;
            state.proformaInvoiceDocumentRemoveDetails.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(deleteProformaInvoiceDocumentsAction.rejected, (state, action) => {
            state.proformaInvoiceDocumentRemoveDetails.apiMsg.message = action.error.message;
            state.proformaInvoiceDocumentRemoveDetails.apiMsg.status = action.meta.requestStatus;
        });

        // removecustomerDocument end

        // addcustomerdocument start

        builder.addCase(addProformaInvoiceDocumentAction.fulfilled, (state, action) => {
            state.proformaInvoiceDocumentInsertDetails.data = action.payload.data;
            state.proformaInvoiceDocumentInsertDetails.apiMsg.status = action.meta.requestStatus;
            state.proformaInvoiceDocumentInsertDetails.apiMsg.message = action.payload.message.message;

            if (state.proformaInvoiceDocuments.data.records.length) {
                state.proformaInvoiceDocuments.data.records.unshift(action.payload.data);
            }
            else {
                state.proformaInvoiceDocuments.data.records.push(action.payload.data);
            }

            toast.success(action.payload.message.message, {
                position: toast.POSITION.BOTTOM_CENTER,
            });
        });
        builder.addCase(addProformaInvoiceDocumentAction.pending, (state, action) => {
            state.proformaInvoiceDocumentInsertDetails.data = null;
            state.proformaInvoiceDocumentInsertDetails.apiMsg.message = action.meta.requestStatus;;
            state.proformaInvoiceDocumentInsertDetails.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(addProformaInvoiceDocumentAction.rejected, (state, action) => {
            state.proformaInvoiceDocumentInsertDetails.apiMsg.message = action.error.message;
            state.proformaInvoiceDocumentInsertDetails.apiMsg.status = action.meta.requestStatus;
            toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                position: toast.POSITION.BOTTOM_CENTER,

            });
        });

        // addcustomerdocument end

        // getall products action start


        builder.addCase(getAllProductsAction.fulfilled, (state, action) => {
            state.barcodeProductList.data.records = action.payload.data;
            state.barcodeProductList.apiMsg.status = action.meta.requestStatus;

        });
        builder.addCase(getAllProductsAction.pending, (state, action) => {
            state.barcodeProductList.data.records = null;
            state.barcodeProductList.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllProductsAction.rejected, (state, action) => {
            state.barcodeProductList.apiMsg.status = action.meta.requestStatus;
        });

        // getall  products action end


        // getall invoice products action start

        builder.addCase(getAllInvoiceProductsAction.fulfilled, (state, action) => {
            state.invoiceProductList.data.records = action.payload.data;
            state.invoiceProductList.apiMsg.status = action.meta.requestStatus;

        });
        builder.addCase(getAllInvoiceProductsAction.pending, (state, action) => {
            state.invoiceProductList.data.records = null;
            state.invoiceProductList.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllInvoiceProductsAction.rejected, (state, action) => {
            state.invoiceProductList.apiMsg.status = action.meta.requestStatus;
        });

        // getall invoice products action end

        // convert proformatoinvoice action start


        builder.addCase(convertProformaToInvoiceAction.fulfilled, (state, action) => {
            state.convertToInvoiceDetails.data = action.payload.data;
            state.convertToInvoiceDetails.apiMsg.status = action.meta.requestStatus;
            state.convertToInvoiceDetails.apiMsg.message = action.payload.message.message;
            state.proformaInvoices.data.records = state.proformaInvoices.data.records.filter(doc => doc.id !== action.meta.arg.id)

            toast.success(action.payload.message.message, {
                position: toast.POSITION.BOTTOM_CENTER,
            });
        });
        builder.addCase(convertProformaToInvoiceAction.pending, (state, action) => {
            state.convertToInvoiceDetails.data = null;
            state.convertToInvoiceDetails.apiMsg.message = action.meta.requestStatus;;
            state.convertToInvoiceDetails.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(convertProformaToInvoiceAction.rejected, (state, action) => {
            state.convertToInvoiceDetails.apiMsg.message = action.error.message;
            state.convertToInvoiceDetails.apiMsg.status = action.meta.requestStatus;
        });

        // convert proformatoinvoice action end

        // delete invoice action start

        builder.addCase(deleteInvoiceAction.fulfilled, (state, action) => {
            state.deleteProformaInvoiceDetails.data = action.payload.data;
            state.deleteProformaInvoiceDetails.apiMsg.status = action.meta.requestStatus;
            state.deleteProformaInvoiceDetails.apiMsg.message = action.payload.message.message;
            state.proformaInvoices.data.records = state.proformaInvoices.data.records.filter(doc => doc.id !== action.meta.arg.id)

            toast.success(action.payload.message.message, {
                position: toast.POSITION.BOTTOM_CENTER,
            });
        });
        builder.addCase(deleteInvoiceAction.pending, (state, action) => {
            state.deleteProformaInvoiceDetails.data = null;
            state.deleteProformaInvoiceDetails.apiMsg.message = action.meta.requestStatus;;
            state.deleteProformaInvoiceDetails.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(deleteInvoiceAction.rejected, (state, action) => {
            state.deleteProformaInvoiceDetails.apiMsg.message = action.error.message;
            state.deleteProformaInvoiceDetails.apiMsg.status = action.meta.requestStatus;
        });

        // delete invoice action end

    },
});
export const { RESET, ResetProformaInvoiceDetailsState, ResetInvoiceDocumentRemoveState, ResetInvoiceDocumentUploadState, ResetBarcodeProductsList, ResetDeleteDetailsState } = proformaInvoicesReducer.actions;

export default proformaInvoicesReducer.reducer;

