import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiCall } from "../../API";



export const getAllActiveVatData = createAsyncThunk(
  "masterdata/vat",
  async (data) => {

    console.log("data", data)
    return apiCall(`vat/GetActive?all=${true}`, "GET", {}, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            return {
              ...response.data,
            }
          } else {
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);
export const getAllActiveBusinessData = createAsyncThunk(
  "masterdata/businesses",
  async (data) => {
    return apiCall("business/GetAllActive", "GET", {}, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            return {
              ...response.data,
            }
          } else {
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);
export const getAllDepartmentData = createAsyncThunk(
  "masterdata/departments",
  async (data) => {
    return apiCall(`department/getactive?all=${true}`, "GET", {}, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            return {
              ...response.data,
            }
          } else {
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);
export const getAllSubDepartmentData = createAsyncThunk(
  "masterdata/subdepartment",
  async (data) => {
    return apiCall(`subdepartment/GetActive`, "POST", {}, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            return {
              ...response.data,
            }
          } else {
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);
export const getAllProductGroupData = createAsyncThunk(
  "masterdata/productgroup",
  async (data) => {
    return apiCall("productgroup/GetActive", "GET", {}, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            return {
              ...response.data,
            }
          } else {
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);
export const getAllPendingApprovals = createAsyncThunk(
  "masterdata/pendingapprovals",
  async (data) => {
    return apiCall("pendingapproval", "GET", {}, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            return {
              ...response.data,
            }
          } else {
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);
export const getAllProductData = createAsyncThunk(
  "masterdata/product",
  async (data) => {
    return apiCall("product", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            return {
              ...response.data,
            }
          } else {
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);
export const getAllProductDataByBarcode = createAsyncThunk(
  "masterdata/allproductdatabybarcode",
  async (data) => {
    return apiCall("product", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            return {
              ...response.data,
            }
          } else {
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);
export const getAllActiveSupplier = createAsyncThunk(
  "masterdata/getAllActiveSupplier",
  async (data) => {
    return apiCall(`supplier/getallactive?all=${true}`, "GET", {}, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            return {
              ...response.data,
            }
          } else {
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);
export const getAllActiveBrand = createAsyncThunk(
  "masterdata/getAllActiveBrand",
  async (data) => {
    return apiCall("brand/getactive", "GET", {}, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            return {
              ...response.data,
            }
          } else {
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);
export const getProductByBarcode = createAsyncThunk(
  "masterdata/GetProductByBarcode",
  async (data) => {
    return apiCall("product/getproductbybarcode?barcode=" + data.barcode, "GET", {}, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            return {
              ...response.data,
            }
          } else {
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);

export const getAllPopUpProductsAction = createAsyncThunk(
  "Product/popupproduct",
  async (data) => {
    return apiCall(`product/popupproduct${data.query ? data.query : ""}`, "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            return {
              ...response.data,
            }
          } else {
            console.log("response error", response.data.message);
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);

export const getAllDropDownData = createAsyncThunk(
  "masterdata/GetDropdownData",
  async (data) => {
    return apiCall(`DropDown/GetDropDownData`, "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            return {
              ...response.data,
            }
          } else {
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);