import { createSlice } from "@reduxjs/toolkit";
import { getAllEmailTemplateAction, getAllEmailTemplateTypesAction, insertUpdateEmailTemplateAction } from "../../actions/manage-settings/emailTemplateActions";
import { toast } from 'react-toastify';
const INITIAL_STATE = {
    emailTemplates: {
        data: {
            records: [],
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    emailTemplateTypes: {
        data: {
            records: [],
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },

    insertUpdateEmailTemplateDetail: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },
};

const EmailTemplatesReducer = createSlice({
    name: "EmailTemplates",
    initialState: INITIAL_STATE,
    reducers: {

        RESET(state, action) {
            state = INITIAL_STATE;
        },
        ResetEmailState(state, action) {
            state.insertUpdateEmailTemplateDetail = INITIAL_STATE.insertUpdateEmailTemplateDetail;
        },
    },
    extraReducers: (builder) => {
        //------------------------------- getallemailtemplates start -------------------------
        builder.addCase(getAllEmailTemplateAction.fulfilled, (state, action) => {
            state.emailTemplates.data.records = action.payload.data;
            state.emailTemplates.apiMsg.status = action.meta.requestStatus;
            state.emailTemplates.apiMsg.message = action.payload.message.message;
        });
        builder.addCase(getAllEmailTemplateAction.pending, (state, action) => {
            state.emailTemplates.data.records = null;
            state.emailTemplates.apiMsg.message = action.meta.requestStatus;;
            state.emailTemplates.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllEmailTemplateAction.rejected, (state, action) => {
            state.emailTemplates.apiMsg.message = action.error.message;
            state.emailTemplates.apiMsg.status = action.meta.requestStatus;
        });
        //------------------------------- getallemailtemplates end -------------------------

        //------------------------------- insertUpdateEmailTemplate start -------------------------

        builder.addCase(
            insertUpdateEmailTemplateAction.fulfilled,
            (state, action) => {

                state.insertUpdateEmailTemplateDetail.data = action.payload.data.data;
                state.insertUpdateEmailTemplateDetail.apiMsg.status = action.meta.requestStatus;
                state.insertUpdateEmailTemplateDetail.apiMsg.message = action.payload.message.message;

        
                toast.success(action.payload.message.message, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "dept-error"
                });

            }
        );
        builder.addCase(
            insertUpdateEmailTemplateAction.pending,
            (state, action) => {
                state.insertUpdateEmailTemplateDetail.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            insertUpdateEmailTemplateAction.rejected,
            (state, action) => {
                console.log(action.error.message);
                state.insertUpdateEmailTemplateDetail.apiMsg.message = action.error.message;
                state.insertUpdateEmailTemplateDetail.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "dept-error"
                });
            }
        );

        //------------------------------- insertUpdateEmailTemplate end -------------------------
        builder.addCase(getAllEmailTemplateTypesAction.fulfilled, (state, action) => {
            state.emailTemplateTypes.data.records = action.payload.data;
            state.emailTemplateTypes.apiMsg.status = action.meta.requestStatus;
            state.emailTemplateTypes.apiMsg.message = action.payload.message.message;
        });
        builder.addCase(getAllEmailTemplateTypesAction.pending, (state, action) => {
            state.emailTemplateTypes.data.records = null;
            state.emailTemplateTypes.apiMsg.message = action.meta.requestStatus;;
            state.emailTemplateTypes.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllEmailTemplateTypesAction.rejected, (state, action) => {
            state.emailTemplateTypes.apiMsg.message = action.error.message;
            state.emailTemplateTypes.apiMsg.status = action.meta.requestStatus;
        });


    },
});

export const { RESET, ResetEmailState } = EmailTemplatesReducer.actions;
export default EmailTemplatesReducer.reducer;
