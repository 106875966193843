import { createSlice } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import { pendingSyncGetDataAction } from "../../actions/manage-settings/pendingSyncAction";

const INITIAL_STATE = {
    pendingSyncRecords: {
        data: {
            records: [],
            totalCount: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
};

const pendingSyncReducer = createSlice({
    name: "pendingSyncReducer",
    initialState: INITIAL_STATE,
    reducers: {

        RESET(state, action) {
            state = INITIAL_STATE;
        },
    },
    extraReducers: (builder) => {
        //------------------------------- getallpendingsyncdata start -------------------------
        builder.addCase(pendingSyncGetDataAction.fulfilled, (state, action) => {
            // console.log("here1", action)

            state.pendingSyncRecords.data.records = action.payload.data.data;
            state.pendingSyncRecords.apiMsg.status = action.meta.requestStatus;
            state.pendingSyncRecords.apiMsg.message = action.payload.message;
        });
        builder.addCase(pendingSyncGetDataAction.pending, (state, action) => {
            // console.log("here2", action)

            state.pendingSyncRecords.data.records = null;
            state.pendingSyncRecords.apiMsg.message = action.meta.requestStatus;
            state.pendingSyncRecords.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(pendingSyncGetDataAction.rejected, (state, action) => {
            // console.log("here3", action)
            state.pendingSyncRecords.apiMsg.message = action.error.message;
            state.pendingSyncRecords.apiMsg.status = action.meta.requestStatus;
        });
        //------------------------------- getallpendingsyncdata end -------------------------

        //------------------------------- insertupdategroupmappingtable start -------------------------

        //------------------------------- insertupdategroupmappingtable end -------------------------



    },
});

export const { RESET } = pendingSyncReducer.actions;
export default pendingSyncReducer.reducer;