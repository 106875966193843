import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiCall } from "../../../API";


export const getAllActiveBusinessData = createAsyncThunk(
  "StockAdjustements/businesses",
  async (data) => {
    return apiCall("business/GetActive", "GET", {}, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            return {
              ...response.data,
            }
          } else {
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);


export const insertUpdateStockAdjustementsAction = createAsyncThunk(
  "StockAdjustements/insertupdate",
  async (data) => {
    return apiCall("stockAdjustment/add", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            let message = response.data.message
            return {
             data:{
              ...response.data,
            },
            message:{
              code:response.data.message.code,
              type:response.data.message.type,
              message:response.data.message.message
            },
            newRecord:data.id ? false : true
            }
          } else {
            if(!response.data.message && response.data.errors && response.data.errors.length){
              response.data.message=response.data.errors[0];
            }
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
        else{
          return Promise.reject({
            ...response.data,
          });
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);

export const getAllStockAdjustmentProducts = createAsyncThunk(
  "StockAdjustment/product",
  async (data) => {
    return apiCall(`product/popupproduct${data.query ? data.query : ""}`, "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            //console.log('response.data ',response.data)
            return {
              ...response.data,
            }
          } else {
            console.log("response error", response.data.message);
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);