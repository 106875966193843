import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiCall, apiFileUploadCall } from "../../../API";

export const getAllProformaInvoicesAction = createAsyncThunk(
    "ProformaInvoice/GetAllProformaInvoices",
    async (data) => {
        const { filterData, query } = data;
        return apiCall(`customerinvoice/getallproformainvoice${query ? query : ""}`, "POST", filterData, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {

                        return {
                            ...response.data,
                        }
                    } else {
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);

export const getProformaInvoiceAction = createAsyncThunk(
    "ProformaInvoice/GetProformaInvoice",
    async (data) => {
        return apiCall("customerinvoice/getcustomerinvoice", "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {

                        return {
                            ...response.data,
                        }
                    } else {
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);


export const insertUpdateProformaInvoiceAction = createAsyncThunk(
    "ProformaInvoice/insertupdateproformainvoice",
    async (data) => {
        return apiCall("customerinvoice/addProformaInvoice", "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        let message = response.data.message
                        return {
                            data: {
                                ...response.data,
                            },
                            message: {
                                code: response.data.message.code,
                                type: response.data.message.type,
                                message: response.data.message.message
                            },
                            newRecord: data.id ? false : true
                        }
                    } else {
                        if (!response.data.message && response.data.errors && response.data.errors.length) {
                            response.data.message = response.data.errors[0];
                        }
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
                else {
                    return Promise.reject({
                        ...response.data,
                    });
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);

export const proformaInvoiceActiveInactiveAction = createAsyncThunk(
    'ProformaInvoice/activeinactive',
    async (data) => {
        return apiCall("customerinvoice/activeinactive", "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        return {
                            ...response.data,
                        }
                    } else {
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
)

export const addProformaInvoiceDocumentAction = createAsyncThunk(
    "ProformaInvoice/addProformaInvoiceDocument",
    async (data) => {
        return apiFileUploadCall("customerinvoice/addcustomerinvoicedocument", "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        return {
                            ...response.data,
                        }
                    } else {
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);

export const getProformaInvoiceDocumentsAction = createAsyncThunk(
    "ProformaInvoice/getProformaDocuments",
    async (data) => {
        return apiCall("customerinvoice/getCustomerInvoiceDocuments", "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        return {
                            ...response.data,
                        }
                    } else {
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);


export const deleteProformaInvoiceDocumentsAction = createAsyncThunk(
    "ProformaInvoice/removeProformaInvoiceDocuments",
    async (data) => {
        return apiCall("customerinvoice/removeCustomerInvoiceDocument", "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {

                        return {
                            ...response.data,
                        }
                    } else {
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);

export const getAllProductsAction = createAsyncThunk(
    "ProformaInvoice/getAllProducts",
    async (data) => {
        return apiCall(`customerInvoice/getproductforcustomerinvoice${data.query ? data.query : ""}`, "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        return {
                            ...response.data,
                        }
                    } else {
                        //  console.log("response error", response.data.message);
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);

export const getAllInvoiceProductsAction = createAsyncThunk(
    "ProformaInvoice/getAllInvoiceProductsAction",
    async (data) => {
        return apiCall(`customerInvoice/getproductforcustomerinvoice${data.query ? data.query : ""}`, "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        return {
                            ...response.data,
                        }
                    } else {
                        //  console.log("response error", response.data.message);
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);



export const convertProformaToInvoiceAction = createAsyncThunk(
    "ProformaInvoice/convertProformaToInvoice",
    async (data) => {
        return apiCall("customerinvoice/convertinvoice", "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {

                        return {
                            ...response.data,
                        }
                    } else {
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);

export const deleteInvoiceAction = createAsyncThunk(
    "ProformaInvoice/deleteInvoice",
    async (data) => {
        return apiCall("customerinvoice/deleteinvoice", "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {

                        return {
                            ...response.data,
                        }
                    } else {
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);







