import { createSlice } from "@reduxjs/toolkit";
import { getAllPurchaseOrderHistory, getPurchaseOrderHistory } from "../../actions/purchase-orders/orderHistoryAction";

const INITIAL_STATE = {

    purchaseOrderHistory: {
        data: {
            records: null,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    selectedPurchaseOrderHistory: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    }
};

const orderHistoryReducer = createSlice({
    name: "orderHistory",
    initialState: INITIAL_STATE,
    reducers: {
        ResetPurchaseOrderHistoryState(state, action) {
            state.selectedPurchaseOrderHistory = INITIAL_STATE.selectedPurchaseOrderHistory;
        }

    },


    extraReducers: (builder) => {


        // getallorderhistory start

        builder.addCase(getAllPurchaseOrderHistory.fulfilled, (state, action) => {
            state.purchaseOrderHistory.data.records = action.payload.data;
            state.purchaseOrderHistory.apiMsg.status = action.meta.requestStatus;

        });
        builder.addCase(getAllPurchaseOrderHistory.pending, (state, action) => {
            state.purchaseOrderHistory.data.records = null;
            state.purchaseOrderHistory.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllPurchaseOrderHistory.rejected, (state, action) => {
            state.purchaseOrderHistory.apiMsg.status = action.meta.requestStatus;
        });

        // getallorderhistory end


        // getpurchaseorderhistory start

        builder.addCase(getPurchaseOrderHistory.fulfilled, (state, action) => {
            state.selectedPurchaseOrderHistory.data = action.payload.data
            state.selectedPurchaseOrderHistory.apiMsg.status = action.meta.requestStatus;
            state.selectedPurchaseOrderHistory.apiMsg.message = action.payload.message.message;
        });

        builder.addCase(getPurchaseOrderHistory.pending, (state, action) => {
            state.selectedPurchaseOrderHistory.data = null;
            state.selectedPurchaseOrderHistory.apiMsg.message = action.meta.requestStatus;;
            state.selectedPurchaseOrderHistory.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(getPurchaseOrderHistory.rejected, (state, action) => {
            state.selectedPurchaseOrderHistory.apiMsg.message = action.error.message;
            state.selectedPurchaseOrderHistory.apiMsg.status = action.meta.requestStatus;
        });

        // getpurchaseOrderHistory end
    }
});

export const { ResetPurchaseOrderHistoryState } = orderHistoryReducer.actions;
export default orderHistoryReducer.reducer;
