import { createSlice } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import { getAllStockAdjustmentsAction } from "../../../actions/reports/stock-reports/stockAdjustmentAction";

const INITIAL_STATE = {
    stockAdjustment: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
};

const stockAdjustmentReducer = createSlice({
    name: "Reports",
    initialState: INITIAL_STATE,
    reducers: {
        RESET(state, action) {
            state.stockAdjustment = INITIAL_STATE.stockAdjustment;
        },


    },
    extraReducers: (builder) => {
        //------------------------------- getAllStockAdjustmentsAction start -------------------------
        // eslint-disable-next-line
        builder.addCase(getAllStockAdjustmentsAction.fulfilled, (state, action) => {
            state.stockAdjustment.data = action.payload.data;
            state.stockAdjustment.apiMsg.status = action.meta.requestStatus;
            // state.stockAdjustment.apiMsg.message = action.payload.message.message;
        }),
            builder.addCase(getAllStockAdjustmentsAction.pending, (state, action) => {
                //console.log("getAllStockAdjustmentsAction pending", action);
                state.stockAdjustment.apiMsg.status = action.meta.requestStatus;
            }),
            builder.addCase(getAllStockAdjustmentsAction.rejected, (state, action) => {
                //console.log("getAllStockAdjustmentsAction rejected", action);
                state.stockAdjustment.apiMsg.message = action.error.message;
                state.stockAdjustment.apiMsg.status = action.meta.requestStatus;
            })
        //------------------------------- getAllSalesSummaryReportACTION end -------------------------

                
    }
});

export const { RESET } = stockAdjustmentReducer.actions;
export default stockAdjustmentReducer.reducer;
