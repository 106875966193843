import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiCall, apiFileUploadCall } from "../../../API";
import { toast } from "react-toastify";
export const getAllSubProductsAction = createAsyncThunk(
  "Product/subproduct",
  async (data) => {
    return apiCall(`product/getall${data.query ? data.query : ""}`, "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            //console.log('response.data ',response.data)
            return {
              ...response.data,
            }
          } else {
            console.log("response error", response.data.message);
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);
export const getSubProductsAction = createAsyncThunk(
  "Product/getsubproduct",
  async (data) => {
    return apiCall("Product/getproduct", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            //console.log('response.data ',response.data)
            return {
              ...response.data,
            }
          } else {
            console.log("response error", response.data.message);
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);
export const getSubProductQtyHistoryAction = createAsyncThunk(
  "Product/GetSubProductQtyHistory",
  async (data) => {
    return apiCall("Product/GetProductQtyHistory", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            //console.log('response.data ',response.data)
            return {
              ...response.data,
            }
          } else {
            console.log("response error", response.data.message);
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);
export const insertUpdateSubProductAction = createAsyncThunk(
  "Product/insertupdateSubProduct",
  async (data) => {
    return apiCall("Product/add", "POST", data, false)
      .then((response) => {
        // console.log(response);
        if (response.status === 200) {
          if (!response.data.hasError) {
            //console.log('response.data ',response.data.message)
            let message = response.data.message
            return {
              data: {
                ...response.data,
              },
              message: {
                code: response.data.message.code,
                type: response.data.message.type,
                message: response.data.message.message
              },
              newRecord: data.id ? false : true
            }
          } else {
            console.log("response error", response.data.message);
            if (!response.data.message && response.data.errors && response.data.errors.length) {
              response.data.message = response.data.errors[0];
            }
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
        else {
          return Promise.reject({
            ...response.data,
          });
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);
export const subProductActiveInactiveAction = createAsyncThunk(
  'Product/subProductActiveInactive',
  async (data) => {
    return apiCall("Product/activeinactive", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            return {
              ...response.data,
            }
          } else {
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
)
export const getMappedSubProductAction = createAsyncThunk(
  "Product/getmappedsubproduct",
  async (data) => {
    return apiCall("supplierproduct/getmapped?productId=" + data.productId + "&productBarcode=" + data.productBarcode, "GET", {}, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            //console.log('response.data ',response.data)
            return {
              ...response.data,
            }
          } else {
            console.log("response error", response.data.message);
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);
export const getCostSubProductAction = createAsyncThunk(
  "Product/getcostsubproduct",
  async (data) => {
    console.log("COSTAction", data)
    return apiCall("supplierproductcosthistory?productId=" + data.productId, "GET", {}, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            //console.log('response.data ',response.data)
            return {
              ...response.data,
            }
          } else {
            console.log("response error", response.data.message);
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);
export const getPriceSubProductAction = createAsyncThunk(
  "Product/getPriceSubProduct",
  async (data) => {
    // console.log("pricedata", data);
    return apiCall("productpricehistory?productId=" + data.productId, "GET", {}, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            // console.log('response.data ',response.data)
            return {
              ...response.data,
            }
          } else {
            console.log("response error", response.data.message);
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);

