import { createSlice } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import { getAllSupplierExpensesInvoicesAction, addSupplierExpensesDocumentAction, getSupplierExpensesDocumentsAction, deleteSupplierExpensesDocumentsAction, insertUpdateSupplierExpensesAction, getPaymentMethods, getAllSupplierAction, getSupplierExpenseAction, getAllProductsAction } from "../../actions/supplier/supplierExpensesInvoicesAction";

const INITIAL_STATE = {
  supplierexpensesinvoices: {
    data: {
      records: [],
      totalRecords: 0,
    },
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },

  insertUpdateSupplierExpensesDetails: {
    data: {},

    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
    newRecord: false
  },
  selectedSupplierExpensesInvoice: {
    data: null,
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },
  activeInactiveDetails: {
    data: {},

    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
    newRecord: false
  },


  supplierExpensesInvoicesDocuments: {
    data: {
      records: [],
      totalRecords: 0,
    },
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },
  supplierExpensesInvoicesDocumentInsertDetails: {
    data: {},
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },
  supplierExpensesInvoicesDocumentRemoveDetails: {
    data: null,
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },

  paymentMethod: {
    data: {
      records: [],
      totalRecords: 0,
    },
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },
  supplier: {
    data: {
      records: [],
      totalRecords: 0,
    },
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },
  barcodeProductList: {
    data: {
      records: [],
      totalRecords: 0,
    },
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  }

};

const supplierExpensesInvoicesReducer = createSlice({
  name: "supplierexpensesinvoices",
  initialState: INITIAL_STATE,
  reducers: {
    RESET(state, action) {
      state = INITIAL_STATE;
    },
    ResetSupplierExpensesInvoicesState(state, action) {
      state.insertUpdateSupplierExpensesDetails = INITIAL_STATE.insertUpdateSupplierExpensesDetails;
      state.selectedSupplierExpensesInvoice = INITIAL_STATE.selectedSupplierExpensesInvoice;
      state.approveDetails = INITIAL_STATE.approveDetails;
      state.rejectDetails = INITIAL_STATE.rejectDetails;
      state.supplierExpensesInvoicesDocuments = INITIAL_STATE.supplierExpensesInvoicesDocuments;
      state.activeInactiveDetails = INITIAL_STATE.activeInactiveDetails;
    },
    ResetDocumentRemoveState(state, action) {
      state.supplierExpensesInvoicesDocumentRemoveDetails = INITIAL_STATE.supplierExpensesInvoicesDocumentRemoveDetails;
    },
    ResetDocumentUploadState(state, action) {
      state.supplierExpensesInvoicesDocumentInsertDetails = INITIAL_STATE.supplierExpensesInvoicesDocumentInsertDetails;
    },
    ResetBarcodeProductsList(state, action) {
      state.barcodeProductList = INITIAL_STATE.barcodeProductList;
    }

  },
  extraReducers: (builder) => {

    //  -------- getAll Supplier Action start--------------------

    builder.addCase(getAllSupplierAction.fulfilled, (state, action) => {
      // console.log("getAllSupplierAction fulfilled", action);

      state.supplier.data.records = action.payload.data;
      state.supplier.apiMsg.status = action.meta.requestStatus;
    });
    builder.addCase(getAllSupplierAction.pending, (state, action) => {
      // console.log("getAllSupplierAction pending", action);
      state.supplier.data.records = null;
      state.supplier.apiMsg.message = action.meta.requestStatus;;
      state.supplier.apiMsg.status = action.meta.requestStatus;
    });
    builder.addCase(getAllSupplierAction.rejected, (state, action) => {
      // console.log("getAllSupplierAction rejected", action);
      state.supplier.apiMsg.message = action.error.message;
      state.supplier.apiMsg.status = action.meta.requestStatus;
    });



    //  -------- getAll Supplier Action end--------------------


    // getallpaymentmethod start


    builder.addCase(getPaymentMethods.fulfilled, (state, action) => {
      state.paymentMethod.data.records = action.payload.data;
      state.paymentMethod.apiMsg.status = action.meta.requestStatus;
      state.paymentMethod.apiMsg.message = action.payload.message.message;
    });
    builder.addCase(getPaymentMethods.pending, (state, action) => {
      state.paymentMethod.data.records = null;
      state.paymentMethod.apiMsg.message = action.meta.requestStatus;;
      state.paymentMethod.apiMsg.status = action.meta.requestStatus;
    });
    builder.addCase(getPaymentMethods.rejected, (state, action) => {
      state.paymentMethod.apiMsg.message = action.error.message;
      state.paymentMethod.apiMsg.status = action.meta.requestStatus;
    });


    // getallpaymentmethod end

    //  -------- getAll supplierexpensesinvoices Action start--------------------

    builder.addCase(getAllSupplierExpensesInvoicesAction.fulfilled, (state, action) => {
      // console.log("getAllSupplierExpensesInvoicesAction fulfilled", action);

      state.supplierexpensesinvoices.data.records = action.payload.data;
      state.supplierexpensesinvoices.apiMsg.status = action.meta.requestStatus;
    });
    builder.addCase(getAllSupplierExpensesInvoicesAction.pending, (state, action) => {
      // console.log("getAllSupplierExpensesInvoicesAction pending", action);
      state.supplierexpensesinvoices.data.records = null;
      state.supplierexpensesinvoices.apiMsg.message = action.meta.requestStatus;;
      state.supplierexpensesinvoices.apiMsg.status = action.meta.requestStatus;
    });
    builder.addCase(getAllSupplierExpensesInvoicesAction.rejected, (state, action) => {
      // console.log("getAllSupplierExpensesInvoicesAction rejected", action);
      state.supplierexpensesinvoices.apiMsg.message = action.error.message;
      state.supplierexpensesinvoices.apiMsg.status = action.meta.requestStatus;
    });

    //  -------- getAll supplierexpensesinvoices Action end--------------------


    //  -------- getsupplierexpensesinvoices Action start--------------------


    builder.addCase(getSupplierExpenseAction.fulfilled, (state, action) => {

      state.selectedSupplierExpensesInvoice.data = action.payload.data;
      state.selectedSupplierExpensesInvoice.apiMsg.status = action.meta.requestStatus;

      state.selectedSupplierExpensesInvoice.apiMsg.status = action.meta.requestStatus;
    });
    builder.addCase(getSupplierExpenseAction.pending, (state, action) => {
      state.selectedSupplierExpensesInvoice.data = null;
      state.selectedSupplierExpensesInvoice.apiMsg.message = action.meta.requestStatus;;
      state.selectedSupplierExpensesInvoice.apiMsg.status = action.meta.requestStatus;
    });
    builder.addCase(getSupplierExpenseAction.rejected, (state, action) => {
      state.selectedSupplierExpensesInvoice.apiMsg.message = action.error.message;
      state.selectedSupplierExpensesInvoice.apiMsg.status = action.meta.requestStatus;
    });

    //  -------- getsupplierexpensesinvoice Action end--------------------


    // insert update supplierexpensesinvoices Action start

    builder.addCase(
      insertUpdateSupplierExpensesAction.fulfilled,
      (state, action) => {
        // console.log("insertUpdateSupplierExpensesAction", action);

        state.insertUpdateSupplierExpensesDetails.data = action.payload.data.data;
        state.insertUpdateSupplierExpensesDetails.apiMsg.status = action.meta.requestStatus;
        state.insertUpdateSupplierExpensesDetails.apiMsg.message = action.payload.message.message;

        if (!action.payload.newRecord) {
          let updatedRecord = state.supplierexpensesinvoices.data.records.map(x => {
            if (x.id == action.payload.data.data.id) {
              return action.payload.data.data;
            }
            else {
              return x;
            }
          })

          state.supplierexpensesinvoices.data.records = updatedRecord;
        }
        else {
          if (state.supplierexpensesinvoices.data.records.length) {
            state.supplierexpensesinvoices.data.records.unshift(action.payload.data.data);
          }
          else {
            state.supplierexpensesinvoices.data.records.push(action.payload.data.data);
          }
        }
        toast.success(action.payload.message.message, {
          position: toast.POSITION.BOTTOM_CENTER,
          toastId: "dept-error"
        });

      }
    );
    builder.addCase(
      insertUpdateSupplierExpensesAction.pending,
      (state, action) => {
        //console.log("insertUpdateSupplierExpensesAction pending", action);
        state.insertUpdateSupplierExpensesDetails.apiMsg.status = action.meta.requestStatus;
      }
    );
    builder.addCase(
      insertUpdateSupplierExpensesAction.rejected,
      (state, action) => {
        //   console.log("insertUpdateSupplierExpensesAction rejected", action);
        //   console.log(action.error.message);
        state.insertUpdateSupplierExpensesDetails.apiMsg.message = action.error.message;
        state.insertUpdateSupplierExpensesDetails.apiMsg.status = action.meta.requestStatus;

        toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
          position: toast.POSITION.BOTTOM_CENTER,
          toastId: "dept-error"
        });
      }
    );



    builder.addCase(getSupplierExpensesDocumentsAction.fulfilled, (state, action) => {
      // console.log(action.payload.data,'reducer data');
      state.supplierExpensesInvoicesDocuments.data.records = action.payload.data;
      state.supplierExpensesInvoicesDocuments.apiMsg.status = action.meta.requestStatus;
      state.supplierExpensesInvoicesDocuments.apiMsg.message = action.payload.message.message;
    });
    builder.addCase(getSupplierExpensesDocumentsAction.pending, (state, action) => {
      state.supplierExpensesInvoicesDocuments.data.records = null;
      state.supplierExpensesInvoicesDocuments.apiMsg.message = action.meta.requestStatus;;
      state.supplierExpensesInvoicesDocuments.apiMsg.status = action.meta.requestStatus;
    });
    builder.addCase(getSupplierExpensesDocumentsAction.rejected, (state, action) => {
      state.supplierExpensesInvoicesDocuments.apiMsg.message = action.error.message;
      state.supplierExpensesInvoicesDocuments.apiMsg.status = action.meta.requestStatus;
    });

    // getsupplierdocuments end

    // removesupplierDocument start

    builder.addCase(deleteSupplierExpensesDocumentsAction.fulfilled, (state, action) => {
      state.supplierExpensesInvoicesDocumentRemoveDetails.data = action.payload.data;
      state.supplierExpensesInvoicesDocumentRemoveDetails.apiMsg.status = action.meta.requestStatus;
      state.supplierExpensesInvoicesDocumentRemoveDetails.apiMsg.message = action.payload.message.message;
      state.supplierExpensesInvoicesDocuments.data.records = state.supplierExpensesInvoicesDocuments.data.records.filter(doc => doc.id !== action.meta.arg.id)
      toast.success(action.payload.message.message, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    });
    builder.addCase(deleteSupplierExpensesDocumentsAction.pending, (state, action) => {
      state.supplierExpensesInvoicesDocumentRemoveDetails.data = null;
      state.supplierExpensesInvoicesDocumentRemoveDetails.apiMsg.message = action.meta.requestStatus;;
      state.supplierExpensesInvoicesDocumentRemoveDetails.apiMsg.status = action.meta.requestStatus;
    });
    builder.addCase(deleteSupplierExpensesDocumentsAction.rejected, (state, action) => {
      state.supplierExpensesInvoicesDocumentRemoveDetails.apiMsg.message = action.error.message;
      state.supplierExpensesInvoicesDocumentRemoveDetails.apiMsg.status = action.meta.requestStatus;
    });

    // removesupplierDocument end

    // addsupplierdocument start

    builder.addCase(addSupplierExpensesDocumentAction.fulfilled, (state, action) => {
      // console.log(action.payload.data);
      state.supplierExpensesInvoicesDocumentInsertDetails.data = action.payload.data;
      state.supplierExpensesInvoicesDocumentInsertDetails.apiMsg.status = action.meta.requestStatus;
      state.supplierExpensesInvoicesDocumentInsertDetails.apiMsg.message = action.payload.message.message;

      if (state.supplierExpensesInvoicesDocuments.data.records.length) {
        state.supplierExpensesInvoicesDocuments.data.records.unshift(action.payload.data);
      }
      else {
        state.supplierExpensesInvoicesDocuments.data.records.push(action.payload.data);
      }

      toast.success(action.payload.message.message, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    });
    builder.addCase(addSupplierExpensesDocumentAction.pending, (state, action) => {
      state.supplierExpensesInvoicesDocumentInsertDetails.data = null;
      state.supplierExpensesInvoicesDocumentInsertDetails.apiMsg.message = action.meta.requestStatus;;
      state.supplierExpensesInvoicesDocumentInsertDetails.apiMsg.status = action.meta.requestStatus;
    });
    builder.addCase(addSupplierExpensesDocumentAction.rejected, (state, action) => {
      state.supplierExpensesInvoicesDocumentInsertDetails.apiMsg.message = action.error.message;
      state.supplierExpensesInvoicesDocumentInsertDetails.apiMsg.status = action.meta.requestStatus;
      toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
        position: toast.POSITION.BOTTOM_CENTER,

      });
    });


    // supplierExpenseAdddocument action end


    // getAllBarcodeProducts start

    builder.addCase(getAllProductsAction.fulfilled, (state, action) => {
      state.barcodeProductList.data.records = action.payload.data;
      state.barcodeProductList.apiMsg.status = action.meta.requestStatus;

    });
    builder.addCase(getAllProductsAction.pending, (state, action) => {
      state.barcodeProductList.data.records = null;
      state.barcodeProductList.apiMsg.status = action.meta.requestStatus;
    });
    builder.addCase(getAllProductsAction.rejected, (state, action) => {
      state.barcodeProductList.apiMsg.status = action.meta.requestStatus;
    });

    // getAllBarcodeProducts start


  },
});
export const { RESET, ResetSupplierExpensesInvoicesState, ResetDocumentRemoveState, ResetDocumentUploadState, ResetBarcodeProductsList } = supplierExpensesInvoicesReducer.actions;
export default supplierExpensesInvoicesReducer.reducer;

