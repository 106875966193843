import { createSlice } from "@reduxjs/toolkit";
import { getAllStockInHistory, getStockInHistory } from "../../actions/stock-in/historyAction";

const INITIAL_STATE = {

    StockInHistory: {
        data: {
            records: null,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    selectedStockInHistory: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    }
};

const stockInHistoryReducer = createSlice({
    name: "stockInHistory",
    initialState: INITIAL_STATE,
    reducers: {
        ResetStockInHistoryDetailsState(state, action) {
            state.selectedStockInHistory = INITIAL_STATE.selectedStockInHistory;
        }

    },


    extraReducers: (builder) => {


        // getAllStockInHistory start

        builder.addCase(getAllStockInHistory.fulfilled, (state, action) => {
            state.StockInHistory.data.records = action.payload.data;
            state.StockInHistory.apiMsg.status = action.meta.requestStatus;

        });
        builder.addCase(getAllStockInHistory.pending, (state, action) => {
            state.StockInHistory.data.records = null;
            state.StockInHistory.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllStockInHistory.rejected, (state, action) => {
            state.StockInHistory.apiMsg.status = action.meta.requestStatus;
        });

        // getAllStockInHistory end


        // selectedStockin Start

        builder.addCase(getStockInHistory.fulfilled, (state, action) => {
            state.selectedStockInHistory.data = action.payload.data
            state.selectedStockInHistory.apiMsg.status = action.meta.requestStatus;
            state.selectedStockInHistory.apiMsg.message = action.payload.message.message;
        });

        builder.addCase(getStockInHistory.pending, (state, action) => {
            state.selectedStockInHistory.data = null;
            state.selectedStockInHistory.apiMsg.message = action.meta.requestStatus;;
            state.selectedStockInHistory.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(getStockInHistory.rejected, (state, action) => {
            state.selectedStockInHistory.apiMsg.message = action.error.message;
            state.selectedStockInHistory.apiMsg.status = action.meta.requestStatus;
        });


        //selectedStockin end
    }
});

export const { ResetStockInHistoryDetailsState } = stockInHistoryReducer.actions;
export default stockInHistoryReducer.reducer;
