import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiCall } from "../../../API";

export const pendingSyncGetDataAction = createAsyncThunk(
    "PendingSync/PendingSync",
    async (data) => {
        return apiCall(`GroupMappingWeb/getpendingsyncrecods${data.query ? data.query : ""}`, "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        // console.log(response.data, 'apidata');
                        // let message = response.data.message
                        return {
                            data: {
                                ...response.data,
                            },

                        }
                    } else {
                        console.log("response error", response.data.message);
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
                else {
                    return Promise.reject({
                        ...response.data,
                    });
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);