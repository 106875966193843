import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiCall } from "../../../API";


export const getAllPOSUserAction = createAsyncThunk(

  "POSUser/GetAllPOSUser",
  async (data) => {
    return apiCall("posuser", "POST", data, false)
      .then((response) => {
        // console.log("response",response)
        if (response.status === 200) {
          if (!response.data.hasError) {

            return {
              ...response.data,
            }
          } else {
            console.log("response error", response.data.message);
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);
export const insertUpdatePosUserAction = createAsyncThunk(
  "POSUser/insertupdateposUser",
  async (data) => {
    console.log("api action insert", data)

    return apiCall("posuser/add", "POST", data, false)
      .then((response) => {
        console.log("api response insert update", response);
        if (response.status === 200) {
          if (!response.data.hasError) {
            console.log('response.data ', response.data.message)
            let message = response.data.message
            // console.log("message",message);
            return {
              data: {
                ...response.data,
              },
              message: {
                code: response.data.message.code,
                type: response.data.message.type,
                message: response.data.message.message
              },
              newRecord: data.id ? false : true
            }
          } else {
            console.log("response error", response.data.message);
            if (!response.data.message && response.data.errors && response.data.errors.length) {
              response.data.message = response.data.errors[0];
            }
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
        else {
          return Promise.reject({
            ...response.data,
          });
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);
export const getPOSUserAction = createAsyncThunk(
  "POSUser/GetPosUser",
  async (data) => {
    return apiCall("posuser/getposuser", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            //console.log('response.data ',response.data)
            return {
              ...response.data,
            }
          } else {
            console.log("response error", response.data.message);
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);
export const getPOSUserGroupAction = createAsyncThunk(

  "POSUser/GetAllPOSUserGroup",
  async (data) => {
    return apiCall(`posusergroup/getactive?all=${true}`, "GET", data, false)
      .then((response) => {
        // console.log("response",response)
        if (response.status === 200) {
          if (!response.data.hasError) {

            return {
              ...response.data,
            }
          } else {
            console.log("response error", response.data.message);
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);
export const getAllActiveTillAction = createAsyncThunk(

  "POSUser/getAllActiveTill",
  async (data) => {
    return apiCall(`till/getactive?all=${true}`, "GET", {}, false)
      .then((response) => {
        // console.log("response",response)
        if (response.status === 200) {
          if (!response.data.hasError) {

            return {
              ...response.data,
            }
          } else {
            console.log("response error", response.data.message);
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);
export const activeInactiveAction = createAsyncThunk(
  "POSUser/ActiveInActive",
  async (data) => {
    return apiCall("posuser/activeinactive", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {

            return {
              ...response.data,
            }
          } else {
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);
export const getPosUserComparisonAction = createAsyncThunk(
  "POSUser/GetProductComparison",
  async (data) => {
    return apiCall("posuser/getposusercomparison?id=" + data.id, "POST", null, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            return {
              ...response.data,
            }
          } else {
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);
export const POSUserApproveAction = createAsyncThunk(
  "POSUser/Approve",
  async (data) => {
    return apiCall("posuser/approve?id=" + data.id, "POST", null, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            return {
              ...response.data,
            }
          } else {
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);
export const POSUserRejectAction = createAsyncThunk(
  "POSUser/Reject",
  async (data) => {
    return apiCall("posuser/reject?id=" + data.id, "POST", null, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            return {
              ...response.data,
            }
          } else {
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);

export const getPOSUserSalesAgentAction = createAsyncThunk(

  "POSUser/GetSalesAgent",
  async (data) => {
    return apiCall(`posuser/getsalesagent?businessId=${data.businessId}`, "GET", data, false)
      .then((response) => {
        // console.log("response",response)
        if (response.status === 200) {
          if (!response.data.hasError) {

            return {
              ...response.data,
            }
          } else {
            console.log("response error", response.data.message);
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);
