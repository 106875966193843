
import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiCall } from "../../../API";


export const getAllPurchaseOrderHistory = createAsyncThunk(
    "PurchaseOrder/OrderHistory",
    async (data) => {
        return apiCall(`PurchaseOrder/OrderHistory`, "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        return {
                            ...response.data,
                        }
                    } else {
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);

export const getPurchaseOrderHistory = createAsyncThunk(
    "PurchaseOrder/GetOrderHistory",
    async (data) => {
        return apiCall(`PurchaseOrder/Get`, "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        return {
                            ...response.data,
                        }
                    } else {
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);
