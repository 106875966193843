import React from "react";
import { useSelector } from "react-redux";
import { Routes, Route, Navigate } from "react-router-dom";
import WithLoading from './app/components/shared/WithLoading';
// import { BrowserView, MobileView, isMobile } from 'react-device-detect';
import { ReducerStateContant } from "./app/helper/constants/general-const";
import RouteLoader from "./app/components/shared/RouteLoader";



const Layout = WithLoading(React.lazy(() => import("./app/components/shared/Layout")));

// pages with loading
const LoginPage = WithLoading(React.lazy(() => import("./app/components/Login")));

const DashboardPage = WithLoading(React.lazy(() => import("./app/components/Dashboard")));

const InventoryDashboardPage = WithLoading(React.lazy(() => import("./app/components/inventory/dashboard")));
const InventoryProductsPage = WithLoading(React.lazy(() => import("./app/components/inventory/products/products")));
const InventoryBrandsPage = WithLoading(React.lazy(() => import("./app/components/inventory/brands/brands")));
const InventoryDepartmentsPage = WithLoading(React.lazy(() => import("./app/components/inventory/departments/departments")));
const InventorySubDepartmentsPage = WithLoading(React.lazy(() => import("./app/components/inventory/sub-department/subdepartments")));
const InventoryProductGroupsPage = WithLoading(React.lazy(() => import("./app/components/inventory/report-groups/report-groups")));
const InventoryManualPriceProductsPage = WithLoading(React.lazy(() => import("./app/components/inventory/manual-price-products/manual-price-products")));
const InventoryManualPriceProductGroupsPage = WithLoading(React.lazy(() => import("./app/components/inventory/manual-price-product-groups/manual-price-product-groups")));
const InventoryDynamicBarcodeProductPage = WithLoading(React.lazy(() => import("./app/components/inventory/dynamic-barcode-products/dynamic-barcode-products")));
// const InventoryPayInOutPage = WithLoading(React.lazy(() => import("./app/components/inventory/payinouts/payinouts")));
const InventoryReducedPriceProductsPage = WithLoading(React.lazy(() => import("./app/components/inventory/reduced-price-products/reduced-price-products")));

const InventoryNewspapersPage = WithLoading(React.lazy(() => import("./app/components/inventory/newspapers/newspapers")));

const InventoryMagazinesPage = WithLoading(React.lazy(() => import("./app/components/inventory/magazines/magazine")));



const PromotionPromotionsPage = WithLoading(React.lazy(() => import("./app/components/promotion/promotions/promotions")));

const PromotionDiscountPage = WithLoading(React.lazy(() => import("./app/components/promotion/discounts/discounts")));
const PromotionQRCodePage = WithLoading(React.lazy(() => import("./app/components/promotion/discounts/qrCode")))

const PromotionConditionalVoucherPage = WithLoading(React.lazy(() => import("./app/components/promotion/conditional-vouchers/conditional-vouchers")));

// const PromotionStaffDiscountPage = WithLoading(React.lazy(() => import("./app/components/promotion/staff-discounts/staff-discount")));

const ShopSettingsVatPage = WithLoading(React.lazy(() => import("./app/components/shop-settings/vat/vat")));
const ShopSettingsTillPage = WithLoading(React.lazy(() => import("./app/components/shop-settings/tills/tills")));
const ShopSettingsPosParemetersPage = WithLoading(React.lazy(() => import("./app/components/shop-settings/pos-parameters/pos-parameters")));
const ShopSettingsPosUserGroupsPage = WithLoading(React.lazy(() => import("./app/components/shop-settings/pos-user-groups/pos-user-groups")));
const ShopSettingsPosUsersPage = WithLoading(React.lazy(() => import("./app/components/shop-settings/pos-users/pos-users")));
const ShopSettingsPosUserPage = WithLoading(React.lazy(() => import("./app/components/shop-settings/pos-users/pos-users")));
const ShopSettingsPosUserGroupPage = WithLoading(React.lazy(() => import("./app/components/shop-settings/pos-user-groups/pos-user-groups")));
const ShopSettingsManageSettingsPage = WithLoading(React.lazy(() => import("./app/components/shop-settings/manage-settings/manage-settings")));


const HeadofficeUserRolesPage = WithLoading(React.lazy(() => import("./app/components/owner-settings/headoffice-roles/headoffice.roles")));
const HeadofficeUsersPage = WithLoading(React.lazy(() => import("./app/components/owner-settings/headoffice-users/headoffice.users")));
const HeadofficeSettingsPage = WithLoading(React.lazy(() => import("./app/components/owner-settings/headoffice-settings/headoffice-settings")));
const EmailTemplatesPage = WithLoading(React.lazy(() => import("./app/components/owner-settings/Email-Templates/EmailTemplates")));
const TransactionCategoryPage = WithLoading(React.lazy(() => import("./app/components/owner-settings/Transaction-Category/TransactionCategory")));
const SyncUtilityPage = WithLoading(React.lazy(() => import("./app/components/owner-settings/Sync-Utility/SyncUtility")));

const PendingSyncPage = WithLoading(React.lazy(() => import("./app/components/owner-settings/Pending-Sync/PendingSync")));

const StockOutManageStockAdjustmentPage = WithLoading(React.lazy(() => import("./app/components/stock-out-manage/stock-adjustments/stock-adjustment")))


const StockOutManageStockReturnToSupplierPage = WithLoading(React.lazy(() => import("./app/components/stock-out-manage/Stock-Return-To-Suppliers/stock-return-to-supplier")))

const StockOutManageStockTransferToOtherShopPage = WithLoading(React.lazy(() => import("./app/components/stock-out-manage/stock-transfer-to-other-shop/stock-transfer-to-other-shop")))

const StockCountPage = WithLoading(React.lazy(() => import("./app/components/stock-out-manage/stock-count/stock-count")))

const PageNotFoundPage = WithLoading(React.lazy(() => import("./app/components/PageNotFound")));

const AccessDeniedPage = WithLoading(React.lazy(() => import("./app/components/shared/AccessDenied")));

const CustomerCustomerPage = WithLoading(React.lazy(() => import("./app/components/customer/customer/customers")));
const CustomerInvoicesPage = WithLoading(React.lazy(() => import("./app/components/customer/customer-invoices/CustomerInvoices")));
const ProformaInvoicePage = WithLoading(React.lazy(() => import("./app/components/customer/proforma-invoices/ProformaInvoices")));
const QuotationPage = WithLoading(React.lazy(() => import("./app/components/customer/quotations/Quotations")));



const SupplierSupplierPage = WithLoading(React.lazy(() => import("./app/components/supplier/supplier/suppliers")));

const SupplierSupplierProductsPage = WithLoading(React.lazy(() => import("./app/components/supplier/supplier-products/supplier-products")));

const SupplierExpensesInvoicesPage = WithLoading(React.lazy(() => import("./app/components/supplier/expenses-invoices/supplierExpensesInvoices")));

const SupplierCreditNotesPage = WithLoading(React.lazy(() => import("./app/components/supplier/supplier-credit-notes/supplierCreditNotes")));

const SupplierStatementsPage = WithLoading(React.lazy(() => import("./app/components/supplier/supplier-statements/supplierStatements")));

const SupplierPromotionsForShopsPage = WithLoading(React.lazy(() => import("./app/components/supplier/promotions-for-shops/promotions-for-shops")));

const PurchaseOrderPurchaseOrderPage = WithLoading(React.lazy(() => import("./app/components/purchase-orders/purchase-orders/purchaseOrder")));
const PurchaseOrderOrdersHistoryPage = WithLoading(React.lazy(() => import("./app/components/purchase-orders/orders-history/OrdersHistory")));

const InventoryManualPriceProductComparePage = WithLoading(React.lazy(() => import("./app/components/inventory/manual-price-products/manual-price-product-compare")));
const InventoryManualPriceProductGroupComparePage = WithLoading(React.lazy(() => import("./app/components/inventory/manual-price-product-groups/manual-price-product-group-compare")));
const InventoryDynamicBarcodeComparePage = WithLoading(React.lazy(() => import("./app/components/inventory/dynamic-barcode-products/dynamic-barcode-compare")));
const InventoryProductComparePage = WithLoading(React.lazy(() => import("./app/components/inventory/products/product-compare")));
const PromotionDiscountComparePage = WithLoading(React.lazy(() => import("./app/components/promotion/discounts/discount-comapre")));
const PromotionConditionalVoucherComparePage = WithLoading(React.lazy(() => import("./app/components/promotion/conditional-vouchers/conditional-voucher-comapre")));
const PromotionsPromotionComparePage = WithLoading(React.lazy(() => import("./app/components/promotion/promotions/promotion-compare")));
const ShopSettingPosUserPage = WithLoading(React.lazy(() => import("./app/components/shop-settings/pos-users/pos-user-compare")));
const SupplierComaprePage = WithLoading(React.lazy(() => import("./app/components/supplier/supplier/supplier-comapre")));
const NewsPaperComparePage = WithLoading(React.lazy(() => import("./app/components/inventory/newspapers/newspaper-compare")));
const MagazineComparePage = WithLoading(React.lazy(() => import("./app/components/inventory/magazines/magazine-comapre")));
const ReducePriceProductComparePage = WithLoading(React.lazy(() => import("./app/components/inventory/reduced-price-products/reduce-price-product-compare")));

// const PayInOutComparePage = WithLoading(React.lazy(() => import("./app/components/inventory/payinouts/payinout-compare")));

const StockInStockInpage = WithLoading(React.lazy(() => import("./app/components/stock-in/stock-in/stock-in")));
const StockInHistorypage = WithLoading(React.lazy(() => import("./app/components/stock-in/history/History")));

const AssistedOrderPage = WithLoading(React.lazy(() => import("./app/components/purchase-orders/assisted-orders/assistedOrder")));

const LowStockReportPage = WithLoading(React.lazy(() => import("./app/components/reports/stock-report/low-stock-report/LowStockReport")));
const ExpiredProductReportPage = WithLoading(React.lazy(() => import("./app/components/reports/stock-report/product-out-of-date-report/ProductOutOfDateReport")));
const StockSupplierReturnReportPage = WithLoading(React.lazy(() => import("./app/components/reports/stock-report/stock-supplier-return-report/StockSupplierreturnReport")));
const StockTransferReportPage = WithLoading(React.lazy(() => import("./app/components/reports/stock-report/stock-transfer-report/StockTransferReport")));
const StockAdjustmentsReportPage = WithLoading(React.lazy(() => import("./app/components/reports/stock-report/stock-adjustment-report/StockAdjustmentReport")));
const StockReportPage = WithLoading(React.lazy(() => import("./app/components/reports/stock-report/stock-report/StockReport")));
const StockCountReportPage = WithLoading(React.lazy(() => import("./app/components/reports/stock-report/stock-count-report/StockCountReport")));
const StockValuationReportPage = WithLoading(React.lazy(() => import("./app/components/reports/stock-report/stock-valuation-report/StockValuationReport")));

const PriceChangeHistoryReportPage = WithLoading(React.lazy(() => import("./app/components/reports/user-behavior-report/price-change-history-report/PriceChangeHistoryReport")));

const RequireAuth = (props) => {
  return <> {props.active ? props.children : <Navigate to="/auth/login" replace />} </>;
};

const AppRoutes = (props) => {

  const protectedLayout = (
    <>
      {
        <>
          {

            props.authorizedAccess == ReducerStateContant.pending ?
              <RouteLoader />
              :
              <RequireAuth active={props.active}>
                <Layout />

              </RequireAuth>
          }
        </>

      }
    </>
  );

  return (
    <Routes>

      <Route path="/" element={protectedLayout}>
        <Route index element={<DashboardPage />} />
      </Route>
      <Route path="/dashboard" element={protectedLayout}>
        <Route index element={<DashboardPage />} />
      </Route>

      <Route path="/inventory" element={protectedLayout}>
        <Route path="dashboard" element={<InventoryDashboardPage />} />

        <Route path="products">
          <Route index element={<InventoryProductsPage />} />
          <Route path="compare" element={<InventoryProductComparePage />} />
        </Route>
        <Route path="departments">
          <Route path="brands" element={<InventoryBrandsPage />} />
          <Route path="departments" element={<InventoryDepartmentsPage />} />
          <Route path="subdepartments" element={<InventorySubDepartmentsPage />} />
          <Route path="reportgroups" element={<InventoryProductGroupsPage />} />
        </Route>

        <Route path="manualpriceproducts">

          <Route path="manualpriceproducts">
            <Route index element={<InventoryManualPriceProductsPage />} />
            <Route path="compare" element={<InventoryManualPriceProductComparePage />} />
          </Route>

          <Route path="manualpriceproductgroups">
            <Route index element={<InventoryManualPriceProductGroupsPage />} />
            <Route path="compare" element={<InventoryManualPriceProductGroupComparePage />} />
          </Route>
        </Route>

        <Route path="DynamicBarcodes">
          <Route index element={<InventoryDynamicBarcodeProductPage />} />
          <Route path="compare" element={<InventoryDynamicBarcodeComparePage />} />
        </Route>

        <Route path="ReducedPriceProducts">
          <Route index element={<InventoryReducedPriceProductsPage />} />
          <Route path="compare" element={<ReducePriceProductComparePage />} />
        </Route>

        <Route path="NewspapersAndMagazines">
          <Route path="Newspapers">
            <Route index element={<InventoryNewspapersPage />} />
            <Route path="compare" element={<NewsPaperComparePage />} />
          </Route>

          <Route path="Magazines">
            <Route index element={<InventoryMagazinesPage />} />
            <Route path="compare" element={<MagazineComparePage />} />
          </Route>

        </Route>

        {/* <Route path="PayInOutList" element={<InventoryPayInOutPage />} /> */}


        {/* <Route path="PayInOutList">
          <Route index element={<InventoryPayInOutPage />} />
          <Route path="compare" element={<PayInOutComparePage />} />
        </Route> */}
      </Route>



      <Route path="/promotion" element={protectedLayout}>
        <Route path="dashboard" element={<PageNotFoundPage />} />




        <Route path="promotions">
          <Route index element={<PromotionPromotionsPage />} />
          <Route path="compare" element={<PromotionsPromotionComparePage />} />
        </Route>

        <Route path="discounts">
          <Route index element={<PromotionDiscountPage />} />
          <Route path="compare" element={<PromotionDiscountComparePage />} />
        </Route>

        {/* <Route path="staffdiscounts" element={<PromotionStaffDiscountPage />} /> */}
        <Route path="vouchers" element={<PageNotFoundPage />} />

        <Route path="conditionalvouchers">
          <Route index element={<PromotionConditionalVoucherPage />} />
          <Route path="compare" element={<PromotionConditionalVoucherComparePage />} />
        </Route>

      </Route>

      <Route path="/promotion/discounts" element={protectedLayout}>
        <Route path="qrcode" element={<PromotionQRCodePage />} />
      </Route>



      <Route path="/PurchaseOrders" element={protectedLayout} >
        <Route path="PurchaseOrders" element={<PurchaseOrderPurchaseOrderPage />} />
        {/* <Route path="AssistedOrders" element={<AssistedOrderPage />} /> */}
        <Route path="OrdersHistory" element={<PurchaseOrderOrdersHistoryPage />} />
      </Route>


      <Route path="/Customers" element={protectedLayout}>
        <Route path="Customers" element={<CustomerCustomerPage />} />
        <Route path="Invoices" element={<CustomerInvoicesPage />} />
        <Route path="ProformaInvoice" element={<ProformaInvoicePage />} />
        <Route path="Quotation" element={<QuotationPage />} />

      </Route>

      <Route path="/Suppliers" element={protectedLayout}>
        {/* <Route path="Suppliers" element={<SupplierSupplierPage />} /> */}
        <Route path="Suppliers">
          <Route index element={<SupplierSupplierPage />} />
          <Route path="compare" element={<SupplierComaprePage />} />
        </Route>
        <Route path="supplierProducts" element={<SupplierSupplierProductsPage />} />
        <Route path="Invoices" element={<SupplierExpensesInvoicesPage />} />
        <Route path="SupplierCreditNotes" element={<SupplierCreditNotesPage />} />
        <Route path="SupplierStatements" element={<SupplierStatementsPage />} />
        <Route path="promotionsForShops" element={<SupplierPromotionsForShopsPage />} />
      </Route>


      <Route path="/shopsettings" element={protectedLayout}>
        <Route path="vat" element={<ShopSettingsVatPage />} />
        <Route path="gst" element={<ShopSettingsVatPage />} />
        <Route path="till" element={<ShopSettingsTillPage />} />
        <Route path="posparameters" element={<ShopSettingsPosParemetersPage />} />
        <Route path="posusersgroups" element={<ShopSettingsPosUserGroupsPage />} />
        <Route path="pos-users" element={<ShopSettingsPosUsersPage />} />
        <Route path="posusers">
          <Route path="POSUsersGroups" element={<ShopSettingsPosUserGroupPage />} />
          <Route path="posuser">
            <Route index element={<ShopSettingsPosUserPage />} />
            <Route path="compare" element={<ShopSettingPosUserPage />} />
          </Route>

        </Route>
        <Route path="managesettings" element={<ShopSettingsManageSettingsPage />} />

      </Route>


      <Route path="/stockoutormanagement" element={protectedLayout}>
        <Route path="stockadjustments" element={<StockOutManageStockAdjustmentPage />} />
        <Route path="stockreturntosuppliers" element={<StockOutManageStockReturnToSupplierPage />} />

        <Route path="stockouttoothershops" element={<StockOutManageStockTransferToOtherShopPage />} />

        {/* <Route path="StockCount" element={<StockCountPage />} /> */}

      </Route>


      <Route path="/StockIn" element={protectedLayout}>
        <Route path="StockIn" element={<StockInStockInpage />} />
        <Route path="History" element={<StockInHistorypage />} />
      </Route>

      <Route path="/StockIn" element={protectedLayout}>
        <Route path="StockIn" element={<StockInStockInpage />} />
      </Route>

      <Route path="/ownersettings" element={protectedLayout}>
        <Route path="headofficeusers" element={<HeadofficeUsersPage />} />
        <Route path="headofficeuserroles" element={<HeadofficeUserRolesPage />} />
        <Route path="headofficesettings" element={<HeadofficeSettingsPage />} />
        <Route path="emailtemplates" element={<EmailTemplatesPage />} />
        <Route path="transactioncategory" element={<TransactionCategoryPage />} />
        <Route path="syncutility" element={<SyncUtilityPage />} />
        <Route path="pendingsync" element={<PendingSyncPage />} />
      </Route>


      <Route path="Reports" element={protectedLayout}>
        <Route path="StockReport">
          <Route path="LowStock" element={<LowStockReportPage />} />
          <Route path="ExpiredProduct" element={<ExpiredProductReportPage />} />
          <Route path="StockSupplierReturn" element={<StockSupplierReturnReportPage />} />
          <Route path="StockTransfer" element={<StockTransferReportPage />} />
          <Route path="StockAdjustments" element={<StockAdjustmentsReportPage />} />
          {/* <Route path="Stock" element={<StockReportPage />} /> */}
          {/* <Route path="StockCount" element={<StockCountReportPage />} /> */}
          {/* <Route path="StockValuation" element={<StockValuationReportPage />} /> */}
        </Route>
        <Route path="UserBehaviour">
          <Route path="PriceChangeHistory" element={<PriceChangeHistoryReportPage />} />

        </Route>
      </Route>



      <Route path="/auth" >
        <Route path="login" element={<LoginPage />} />
      </Route>
      <Route path="/accessdenied" element={protectedLayout} >
        <Route index element={<AccessDeniedPage />} />
      </Route>
      <Route path="*" element={protectedLayout}>
        <Route path="*" element={<PageNotFoundPage />} />
      </Route>

    </Routes >

  );
};

export default AppRoutes;