import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiCall } from "../../../API";

export const getConditionalVoucherAction = createAsyncThunk(
  "ConditionalVoucher/GetAllConditionalVoucher",
  async (data) => {
    return apiCall("conditionalVoucher", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {

            return {
              ...response.data,
            }
          } else {
            //  console.log("response error", response.data.message);
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
        else {
          return Promise.reject({
            ...response.data.message,
          });
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);
export const insertUpdateConditionalVoucherAction = createAsyncThunk(
  "ConditionalVoucher/insertupdateconditionalvoucher",
  async (data) => {
    return apiCall("conditionalvoucher/add", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            let message = response.data.message
            return {
              data: {
                ...response.data,
              },
              message: {
                code: response.data.message.code,
                type: response.data.message.type,
                message: response.data.message.message
              },
              newRecord: data.id ? false : true
            }
          } else {
            if (!response.data.message && response.data.errors && response.data.errors.length) {
              response.data.message = response.data.errors[0];
            }
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
        else {
          return Promise.reject({
            ...response.data,
          });
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);
export const activeInactiveAction = createAsyncThunk(
  "ConditionalVoucher/ActiveInActive",
  async (data) => {
    return apiCall("conditionalvoucher/activeinactive", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {

            return {
              ...response.data,
            }
          } else {
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);
export const getVoucherAction = createAsyncThunk(
  "ConditionalVoucher/Getconditionalvoucher",
  async (data) => {
    return apiCall("conditionalvoucher/getconditionalvoucher", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {

            return {
              ...response.data,
            }
          } else {
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);
export const getActiveDiscountAction = createAsyncThunk(
  "ConditionalVoucher/GetActiveDiscount",
  async (data) => {
    return apiCall(`discount/getactive`, "GET", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {

            return {
              ...response.data,
            }
          } else {
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);
export const getConditionalVoucherComparisonAction = createAsyncThunk(
  "ConditionalVoucher/GetProductComparison",
  async (data) => {
    return apiCall("conditionalvoucher/getconditionalvouchercomparison?id=" + data.id, "POST", null, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            return {
              ...response.data,
            }
          } else {
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);
export const conditionalVoucherApproveAction = createAsyncThunk(
  "ConditionalVoucher/Approve",
  async (data) => {
    return apiCall("conditionalvoucher/approve?id=" + data.id, "POST", null, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            return {
              ...response.data,
            }
          } else {
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);
export const conditionalVoucherRejectAction = createAsyncThunk(
  "ConditionalVoucher/Reject",
  async (data) => {
    return apiCall("conditionalvoucher/reject?id=" + data.id, "POST", null, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            return {
              ...response.data,
            }
          } else {
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);


