import { createSlice } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import { getAllPriceChangeHistoryAction } from "../../../actions/reports/user-behaviour-report/priceChangeHistoryActions";

const INITIAL_STATE = {
    priceChangeHistory: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },

};

const priceChangeHistoryReducer = createSlice({
    name: "PriceChangeHistoryReducer",
    initialState: INITIAL_STATE,
    reducers: {
        RESET(state, action) {
            state.priceChangeHistory = INITIAL_STATE.priceChangeHistory;
        },


    },
    extraReducers: (builder) => {
        //------------------------------- getAllPriceChangeHistoryAction start -------------------------
        // eslint-disable-next-line
        builder.addCase(getAllPriceChangeHistoryAction.fulfilled, (state, action) => {
            state.priceChangeHistory.data = action.payload.data;
            state.priceChangeHistory.apiMsg.status = action.meta.requestStatus;
            // state.stockTransfer.apiMsg.message = action.payload.message.message;
        }),
            builder.addCase(getAllPriceChangeHistoryAction.pending, (state, action) => {
                //console.log("getAllStockTransferAction pending", action);
                state.priceChangeHistory.apiMsg.status = action.meta.requestStatus;
            }),
            builder.addCase(getAllPriceChangeHistoryAction.rejected, (state, action) => {
                //console.log("getAllStockTransferAction rejected", action);
                state.priceChangeHistory.apiMsg.message = action.error.message;
                state.priceChangeHistory.apiMsg.status = action.meta.requestStatus;
            })
        //------------------------------- getAllPriceChangeHistoryAction end -------------------------
    }
});

export const { RESET } = priceChangeHistoryReducer.actions;
export default priceChangeHistoryReducer.reducer;
