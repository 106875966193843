import { createSlice } from "@reduxjs/toolkit";
import {
  getAllUsersAction,
  insertUpdateUsersAction,
  getUsersAction
} from "../../actions/manage-settings/headofficeUsersActions";
import { toast } from 'react-toastify';
const INITIAL_STATE = {
  usersList: {
    data: {
      records: [],
      totalRecords: 0,
    },
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },
  users: {
    data: {
      records: [],
      totalRecords: 0,
    },
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },
  insertUpdateUser: {
    data: {},

    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
    newRecord: false
  },
};

const headofficeUserRolesReducer = createSlice({
  name: "HeadofficeUserRoles",
  initialState: INITIAL_STATE,
  reducers: {

    RESET(state, action) {
      state = INITIAL_STATE;
    },
    ResetUsersState(state, action) {
      state.insertUpdateUser = INITIAL_STATE.insertUpdateUser;
      state.users = INITIAL_STATE.users;
    },
  },
  extraReducers: (builder) => {
    //------------------------------- getAllBrandsAction start -------------------------
    // eslint-disable-next-line
    builder.addCase(getAllUsersAction.fulfilled, (state, action) => {
      //console.log("getAllBrandsAction", action);
      state.usersList.data.records = action.payload.data;
      state.usersList.apiMsg.status = action.meta.requestStatus;
      state.usersList.apiMsg.message = action.payload.message.message;
    });
    builder.addCase(getAllUsersAction.pending, (state, action) => {
      //console.log("getAllBrandsAction pending", action);
      state.usersList.data.records = null;
      state.usersList.apiMsg.message = action.meta.requestStatus;;
      state.usersList.apiMsg.status = action.meta.requestStatus;
    });
    builder.addCase(getAllUsersAction.rejected, (state, action) => {
      console.log("getAllUsersAction rejected", action);
      state.usersList.apiMsg.message = action.error.message;
      state.usersList.apiMsg.status = action.meta.requestStatus;
    });
    //------------------------------- getAllBrandsAction end -------------------------

    //------------------------------- insertUpdateUser start -------------------------

    builder.addCase(
      insertUpdateUsersAction.fulfilled,
      (state, action) => {
        //console.log("insertUpdateUser", action);

        state.insertUpdateUser.data = action.payload.data.data;
        state.insertUpdateUser.apiMsg.status = action.meta.requestStatus;
        state.insertUpdateUser.apiMsg.message = action.payload.message.message;

        toast.success(action.payload.message.message, {
          position: toast.POSITION.BOTTOM_CENTER,
          toastId: "dept-error"
        });

      }
    );
    builder.addCase(
      insertUpdateUsersAction.pending,
      (state, action) => {
        //console.log("insertUpdateBrandAction pending", action);
        state.insertUpdateUser.apiMsg.status = action.meta.requestStatus;
      }
    );
    builder.addCase(
      insertUpdateUsersAction.rejected,
      (state, action) => {
        console.log("insertUpdateUser rejected", action);
        console.log(action.error.message);
        state.insertUpdateUser.apiMsg.message = action.error.message;
        state.insertUpdateUser.apiMsg.status = action.meta.requestStatus;

        toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
          position: toast.POSITION.BOTTOM_CENTER,
          toastId: "dept-error"
        });
      }
    );

    //------------------------------- insertUpdateUser end -------------------------

     //------------------------------- getUsersAction start -------------------------
    // eslint-disable-next-line
    builder.addCase(getUsersAction.fulfilled, (state, action) => {
      // console.log("getUsersAction fulfilled", action);
      // console.log(action.payload.data);
      state.users.data.records = action.payload.data;
      state.users.apiMsg.status = action.meta.requestStatus;
      state.users.apiMsg.message = action.payload.message.message;
    });
    builder.addCase(getUsersAction.pending, (state, action) => {
      
      state.users.data.records = null;
      state.users.apiMsg.message = action.meta.requestStatus;;
      state.users.apiMsg.status = action.meta.requestStatus;
    });
    builder.addCase(getUsersAction.rejected, (state, action) => {
      console.log("getUsersAction rejected", action);
      state.users.apiMsg.message = action.error.message;
      state.users.apiMsg.status = action.meta.requestStatus;
    });
    //------------------------------- getUsersAction end -------------------------

  },
});

export const { RESET, ResetUsersState } = headofficeUserRolesReducer.actions;
export default headofficeUserRolesReducer.reducer;
