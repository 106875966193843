import { createSlice } from "@reduxjs/toolkit";
import { getAllSubProductsAction, getSubProductsAction, insertUpdateSubProductAction, subProductActiveInactiveAction, getMappedSubProductAction, getCostSubProductAction, getPriceSubProductAction, getSubProductQtyHistoryAction } from "../../actions/inventory/subProductActions";

import { toast } from 'react-toastify';
const INITIAL_STATE = {
    subproduct: {
        data: {
            records: [],
            totalRecords: 0,
        },
        productBusinessList: [],
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    selectedSubProduct: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    insertUpdateSubProductDetails: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },
    subProductActiveInactiveDetails: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },
    mappedSubProductDetails: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },

    costSubProductDetails: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },

    priceSubProductDetails: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },
    subProductQtyHistory: {
        data: {
            records: [],
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },

};

const subProductReducer = createSlice({
    name: "SubProduct",
    initialState: INITIAL_STATE,
    reducers: {

        ResetSubProductDetails(state, action) {
            state.selectedSubProduct = INITIAL_STATE.selectedSubProduct;
            state.insertUpdateSubProductDetails = INITIAL_STATE.insertUpdateSubProductDetails;
            state.costSubProductDetails = INITIAL_STATE.costSubProductDetails;
            state.priceSubProductDetails = INITIAL_STATE.priceSubProductDetails;

        },
        ResetSubProductQtyHistory(state, action) {
            state.subProductQtyHistory = INITIAL_STATE.subProductQtyHistory;
        },

    },
    extraReducers: (builder) => {
        //------------------------------- getAllProductsAction start -------------------------


        builder.addCase(getAllSubProductsAction.fulfilled, (state, action) => {

            state.subproduct.data.records = action.payload.data;
            state.subproduct.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllSubProductsAction.pending, (state, action) => {
            state.subproduct.data.records = null;
            state.subproduct.apiMsg.message = action.meta.requestStatus;
            state.subproduct.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllSubProductsAction.rejected, (state, action) => {
            state.subproduct.apiMsg.message = action.error.message;
            state.subproduct.apiMsg.status = action.meta.requestStatus;
        });
        //------------------------------- getAllProductsAction end -------------------------


        //------------------------------- getProductsAction start -------------------------

        builder.addCase(
            getSubProductsAction.fulfilled,
            (state, action) => {
                //console.log("getProductsAction", action);

                state.selectedSubProduct.data = action.payload.data;
                state.selectedSubProduct.apiMsg.status = action.meta.requestStatus;
                state.selectedSubProduct.apiMsg.message = action.payload.message.message;

            }
        );
        builder.addCase(
            getSubProductsAction.pending,
            (state, action) => {
                //console.log("getProductsAction pending", action);
                state.selectedSubProduct.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            getSubProductsAction.rejected,
            (state, action) => {

                state.selectedSubProduct.apiMsg.message = action.error.message;
                state.selectedSubProduct.apiMsg.status = action.meta.requestStatus;
                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "dept-error"
                });
            }
        );

        //------------------------------- getProductsAction end -------------------------

        //------------------------------- insertUpdateProductAction start -------------------------

        builder.addCase(
            insertUpdateSubProductAction.fulfilled,
            (state, action) => {
                console.log("insertUpdateProductAction", action);

                state.insertUpdateSubProductDetails.data = action.payload.data.data;
                state.insertUpdateSubProductDetails.apiMsg.status = action.meta.requestStatus;
                state.insertUpdateSubProductDetails.apiMsg.message = action.payload.message.message;
                state.insertUpdateSubProductDetails.newRecord = action.meta.arg.id ? false : true;

                toast.success(action.payload.message.message, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "dept-error"
                });

            }
        );
        builder.addCase(
            insertUpdateSubProductAction.pending,
            (state, action) => {
                state.insertUpdateSubProductDetails.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            insertUpdateSubProductAction.rejected,
            (state, action) => {
             
                state.insertUpdateSubProductDetails.apiMsg.message = action.error.message;
                state.insertUpdateSubProductDetails.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "dept-error"
                });
            }
        );

        //------------------------------- insertUpdateProductAction end -------------------------


        //------------------------------- activeInactiveAction start -------------------------

        builder.addCase(
            subProductActiveInactiveAction.fulfilled,
            (state, action) => {
                console.log("activeInactiveAction", action);

                state.subProductActiveInactiveDetails.data = action.payload.data.data;
                state.subProductActiveInactiveDetails.apiMsg.status = action.meta.requestStatus;
                state.subProductActiveInactiveDetails.apiMsg.message = action.payload.message.message;

                let updatedRecord = state.subproduct.data.records.map(x => {
                    if (x.id == action.meta.arg.id) {
                        x.isActive = action.meta.arg.isActive;
                        return x;
                    }
                    else {
                        return x;
                    }
                })



                state.subproduct.data.records = updatedRecord;

                toast.success(action.payload.message.message, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "success"
                });

            }
        );
        builder.addCase(
            subProductActiveInactiveAction.pending,
            (state, action) => {
                state.subProductActiveInactiveDetails.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            subProductActiveInactiveAction.rejected,
            (state, action) => {
                state.subProductActiveInactiveDetails.apiMsg.message = action.error.message;
                state.subProductActiveInactiveDetails.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "error"
                });
            }
        );

        //------------------------------- activeInactiveAction end -------------------------


        // get all mappedProduct action start

        builder.addCase(getMappedSubProductAction.fulfilled, (state, action) => {
            //console.log("getMappedProductAction",action);
            state.mappedSubProductDetails.data.records = action.payload.data;
            state.mappedSubProductDetails.apiMsg.status = action.meta.requestStatus;
            state.mappedSubProductDetails.apiMsg.message = "success";

        });
        builder.addCase(getMappedSubProductAction.pending, (state, action) => {
            // console.log("getMappedProductAction pending", action);
            state.mappedSubProductDetails.data.records = null;
            state.mappedSubProductDetails.apiMsg.message = action.meta.requestStatus;
            state.mappedSubProductDetails.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getMappedSubProductAction.rejected, (state, action) => {
            // console.log("getMappedProductAction rejected", action);
            state.mappedSubProductDetails.apiMsg.message = action.error.message;
            state.mappedSubProductDetails.apiMsg.status = action.meta.requestStatus;
        });

        // get all mappedProduct action end

        // get GetProductQtyHistory start

        builder.addCase(getSubProductQtyHistoryAction.fulfilled, (state, action) => {
            //console.log("GetProductQtyHistory",action);
            state.subProductQtyHistory.data.records = action.payload.data;
            state.subProductQtyHistory.apiMsg.status = action.meta.requestStatus;
            state.subProductQtyHistory.apiMsg.message = "success";

        });
        builder.addCase(getSubProductQtyHistoryAction.pending, (state, action) => {
            // console.log("GetProductQtyHistory pending", action);
            state.subProductQtyHistory.data.records = null;
            state.subProductQtyHistory.apiMsg.message = action.meta.requestStatus;
            state.subProductQtyHistory.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getSubProductQtyHistoryAction.rejected, (state, action) => {
            // console.log("GetProductQtyHistory rejected", action);
            state.subProductQtyHistory.apiMsg.message = action.error.message;
            state.subProductQtyHistory.apiMsg.status = action.meta.requestStatus;
        });

        // get all GetProductQtyHistory end

        // get all costProduct action start


        builder.addCase(getCostSubProductAction.fulfilled, (state, action) => {
            console.log("getCostProductAction", action);
            state.costSubProductDetails.data.records = action.payload.data;
            state.costSubProductDetails.apiMsg.status = action.meta.requestStatus;
            state.costSubProductDetails.apiMsg.message = "success";

        });
        builder.addCase(getCostSubProductAction.pending, (state, action) => {
            // console.log("getCostProductAction pending", action);
            state.costSubProductDetails.data.records = null;
            state.costSubProductDetails.apiMsg.message = action.meta.requestStatus;
            state.costSubProductDetails.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getCostSubProductAction.rejected, (state, action) => {
            // console.log("getMappedProductAction rejected", action);
            state.costSubProductDetails.apiMsg.message = action.error.message;
            state.costSubProductDetails.apiMsg.status = action.meta.requestStatus;
        });

        // get all costProduct action end


        // get all priceProduct action start


        builder.addCase(getPriceSubProductAction.fulfilled, (state, action) => {
            // console.log("getMappedProductAction",action);
            state.priceSubProductDetails.data.records = action.payload.data;
            state.priceSubProductDetails.apiMsg.status = action.meta.requestStatus;
            state.priceSubProductDetails.apiMsg.message = "success";

        });
        builder.addCase(getPriceSubProductAction.pending, (state, action) => {
            // console.log("getMappedProductAction pending", action);
            state.priceSubProductDetails.data.records = null;
            state.priceSubProductDetails.apiMsg.message = action.meta.requestStatus;
            state.priceSubProductDetails.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getPriceSubProductAction.rejected, (state, action) => {
            // console.log("getMappedProductAction rejected", action);
            state.priceSubProductDetails.apiMsg.message = action.error.message;
            state.priceSubProductDetails.apiMsg.status = action.meta.requestStatus;
        });

        // get all priceProduct action end



    },
});             

export const { ResetSubProductDetails, ResetSubProductQtyHistory} = subProductReducer.actions;
export default subProductReducer.reducer;
