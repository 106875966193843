import { createSlice } from "@reduxjs/toolkit";

import { getAllCustomerInvoicesAction, getCustomerInvoiceAction, insertUpdateCustomerInvoiceAction, getCustomerInvoiceDocumentsAction, addCustomerInvoiceDocumentAction, deleteCustomerInvoiceDocumentsAction, customerInvoiceActiveInactiveAction, getAllProductsAction, getAllInvoiceProductsAction, invoicePromotionCalculationAction } from "../../actions/customer/customerInvoiceActions";

import { toast } from 'react-toastify';

const INITIAL_STATE = {
    customerInvoices: {
        data: {
            records: [],
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    selectedCustomerInvoice: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },

    insertUpdateInvoiceDetails: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },
    customerInvoiceActiveInactiveDetails: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },

    customerInvoiceDocuments: {
        data: {
            records: [],
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    customerInvoiceDocumentInsertDetails: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    customerInvoiceDocumentRemoveDetails: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    barcodeProductList: {
        data: {
            records: [],
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    invoiceProductList: {
        data: {
            records: [],
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    promotionCalculationData: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    }

};

const customerInvoicesReducer = createSlice({
    name: "CustomerInvoices",
    initialState: INITIAL_STATE,
    reducers: {
        RESET(state, action) {
            state = INITIAL_STATE;
        },
        ResetCustomerInvoiceDetailsState(state, action) {
            state.insertUpdateInvoiceDetails = INITIAL_STATE.insertUpdateInvoiceDetails;
            state.selectedCustomerInvoice = INITIAL_STATE.selectedCustomerInvoice;
            state.customerInvoiceDocumentInsertDetails = INITIAL_STATE.customerInvoiceDocumentInsertDetails;
            state.customerInvoiceDocuments = INITIAL_STATE.customerInvoiceDocuments;
            state.customerInvoiceActiveInactiveDetails = INITIAL_STATE.customerInvoiceActiveInactiveDetails;
            state.promotionCalculationData = INITIAL_STATE.promotionCalculationData;
        },
        ResetInvoiceDocumentUploadState(state, action) {
            state.customerInvoiceDocumentInsertDetails = INITIAL_STATE.customerInvoiceDocumentInsertDetails;
        },
        ResetInvoiceDocumentRemoveState(state, action) {
            state.customerInvoiceDocumentRemoveDetails = INITIAL_STATE.customerInvoiceDocumentRemoveDetails;
        },
        ResetBarcodeProductsList(state, action) {
            state.barcodeProductList = INITIAL_STATE.barcodeProductList;
            state.invoiceProductList = INITIAL_STATE.invoiceProductList;
        }

    },
    extraReducers: (builder) => {

        //getallcustomeraction start


        builder.addCase(getAllCustomerInvoicesAction.fulfilled, (state, action) => {
            state.customerInvoices.data.records = action.payload.data;
            state.customerInvoices.apiMsg.status = action.meta.requestStatus;
            //state.customer.apiMsg.message = action.payload.message.message;
        });
        builder.addCase(getAllCustomerInvoicesAction.pending, (state, action) => {
            state.customerInvoices.data.records = null;
            //state.customer.apiMsg.message = action.meta.requestStatus;;
            state.customerInvoices.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllCustomerInvoicesAction.rejected, (state, action) => {
            //state.customer.apiMsg.message = action.error.message;
            state.customerInvoices.apiMsg.status = action.meta.requestStatus;
        });
        //getallcustomeraction end

        //getcustomeraction start

        builder.addCase(getCustomerInvoiceAction.fulfilled, (state, action) => {
            state.selectedCustomerInvoice.data = action.payload.data;
            state.selectedCustomerInvoice.apiMsg.status = action.meta.requestStatus;
            state.selectedCustomerInvoice.apiMsg.message = action.payload.message.message;
        });
        builder.addCase(getCustomerInvoiceAction.pending, (state, action) => {
            //console.log("getCustomerAction pending", action);
            state.selectedCustomerInvoice.data = null;
            state.selectedCustomerInvoice.apiMsg.message = action.meta.requestStatus;;
            state.selectedCustomerInvoice.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getCustomerInvoiceAction.rejected, (state, action) => {
            //console.log("getCustomerAction rejected", action);
            state.selectedCustomerInvoice.apiMsg.message = action.error.message;
            state.selectedCustomerInvoice.apiMsg.status = action.meta.requestStatus;
        });

        // getcustomeraction end


        // insert update insertUpdateCustomerAction Action start

        builder.addCase(
            insertUpdateCustomerInvoiceAction.fulfilled,
            (state, action) => {
                //console.log("insertUpdateCustomerAction", action);

                state.insertUpdateInvoiceDetails.data = action.payload.data.data;
                state.insertUpdateInvoiceDetails.apiMsg.status = action.meta.requestStatus;
                state.insertUpdateInvoiceDetails.apiMsg.message = action.payload.message.message;

                if (!action.payload.newRecord) {
                    let updatedRecord = state.customerInvoices.data.records.map(x => {
                        if (x.id == action.payload.data.data.id) {
                            return action.payload.data.data;
                        }
                        else {
                            return x;
                        }
                    })

                    state.customerInvoices.data.records = updatedRecord;
                }
                else {
                    if (state.customerInvoices.data.records.length) {
                        state.customerInvoices.data.records.unshift(action.payload.data.data);
                    }
                    else {
                        state.customerInvoices.data.records.push(action.payload.data.data);
                    }
                }
                toast.success(action.payload.message.message, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "dept-error"
                });

            }
        );
        builder.addCase(
            insertUpdateCustomerInvoiceAction.pending,
            (state, action) => {
                //console.log("insertUpdateCustomerAction pending", action);
                state.insertUpdateInvoiceDetails.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            insertUpdateCustomerInvoiceAction.rejected,
            (state, action) => {
                //   console.log("insertUpdateVatAction rejected", action);
                //   console.log(action.error.message);
                state.insertUpdateInvoiceDetails.apiMsg.message = action.error.message;
                state.insertUpdateInvoiceDetails.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "dept-error"
                });
            }
        );


        //   insert update insertUpdateDetails action end



        // activeinactive customer action start

        builder.addCase(
            customerInvoiceActiveInactiveAction.fulfilled,
            (state, action) => {

                state.customerInvoiceActiveInactiveDetails.data = action.payload.data;
                state.customerInvoiceActiveInactiveDetails.apiMsg.status = action.meta.requestStatus;
                state.customerInvoiceActiveInactiveDetails.apiMsg.message = action.payload.message.message;

                let updatedRecord = state.customerInvoices.data.records.map(x => {

                    if (x.id == action.meta.arg.id) {
                        x.isActive = action.meta.arg.isActive;
                        return x;
                    }
                    else {
                        return x;
                    }
                })



                state.customerInvoices.data.records = updatedRecord;

                toast.success(action.payload.message.message, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "success"
                });

            }
        );
        builder.addCase(
            customerInvoiceActiveInactiveAction.pending,
            (state, action) => {
                state.customerInvoiceActiveInactiveDetails.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            customerInvoiceActiveInactiveAction.rejected,
            (state, action) => {
                state.customerInvoiceActiveInactiveDetails.apiMsg.message = action.error.message;
                state.customerInvoiceActiveInactiveDetails.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "error"
                });
            }
        );

        // activeinactive customer action end



        // getcustomerDocuments start

        builder.addCase(getCustomerInvoiceDocumentsAction.fulfilled, (state, action) => {
            state.customerInvoiceDocuments.data.records = action.payload.data;
            state.customerInvoiceDocuments.apiMsg.status = action.meta.requestStatus;
            state.customerInvoiceDocuments.apiMsg.message = action.payload.message.message;
        });
        builder.addCase(getCustomerInvoiceDocumentsAction.pending, (state, action) => {
            state.customerInvoiceDocuments.data.records = null;
            state.customerInvoiceDocuments.apiMsg.message = action.meta.requestStatus;;
            state.customerInvoiceDocuments.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getCustomerInvoiceDocumentsAction.rejected, (state, action) => {
            state.customerInvoiceDocuments.apiMsg.message = action.error.message;
            state.customerInvoiceDocuments.apiMsg.status = action.meta.requestStatus;
        });

        // getcustomerDocuments end

        // removecustomerDocument start
        builder.addCase(deleteCustomerInvoiceDocumentsAction.fulfilled, (state, action) => {
            state.customerInvoiceDocumentRemoveDetails.data = action.payload.data;
            state.customerInvoiceDocumentRemoveDetails.apiMsg.status = action.meta.requestStatus;
            state.customerInvoiceDocumentRemoveDetails.apiMsg.message = action.payload.message.message;
            state.customerInvoiceDocuments.data.records = state.customerInvoiceDocuments.data.records.filter(doc => doc.id !== action.meta.arg.id)

            toast.success(action.payload.message.message, {
                position: toast.POSITION.BOTTOM_CENTER,
            });
        });
        builder.addCase(deleteCustomerInvoiceDocumentsAction.pending, (state, action) => {
            state.customerInvoiceDocumentRemoveDetails.data = null;
            state.customerInvoiceDocumentRemoveDetails.apiMsg.message = action.meta.requestStatus;;
            state.customerInvoiceDocumentRemoveDetails.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(deleteCustomerInvoiceDocumentsAction.rejected, (state, action) => {
            state.customerInvoiceDocumentRemoveDetails.apiMsg.message = action.error.message;
            state.customerInvoiceDocumentRemoveDetails.apiMsg.status = action.meta.requestStatus;
        });

        // removecustomerDocument end

        // addcustomerdocument start

        builder.addCase(addCustomerInvoiceDocumentAction.fulfilled, (state, action) => {
            state.customerInvoiceDocumentInsertDetails.data = action.payload.data;
            state.customerInvoiceDocumentInsertDetails.apiMsg.status = action.meta.requestStatus;
            state.customerInvoiceDocumentInsertDetails.apiMsg.message = action.payload.message.message;

            if (state.customerInvoiceDocuments.data.records.length) {
                state.customerInvoiceDocuments.data.records.unshift(action.payload.data);
            }
            else {
                state.customerInvoiceDocuments.data.records.push(action.payload.data);
            }

            toast.success(action.payload.message.message, {
                position: toast.POSITION.BOTTOM_CENTER,
            });
        });
        builder.addCase(addCustomerInvoiceDocumentAction.pending, (state, action) => {
            state.customerInvoiceDocumentInsertDetails.data = null;
            state.customerInvoiceDocumentInsertDetails.apiMsg.message = action.meta.requestStatus;;
            state.customerInvoiceDocumentInsertDetails.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(addCustomerInvoiceDocumentAction.rejected, (state, action) => {
            state.customerInvoiceDocumentInsertDetails.apiMsg.message = action.error.message;
            state.customerInvoiceDocumentInsertDetails.apiMsg.status = action.meta.requestStatus;
            toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                position: toast.POSITION.BOTTOM_CENTER,

            });
        });

        // addcustomerdocument end


        // getAllProductsAction start


        builder.addCase(getAllProductsAction.fulfilled, (state, action) => {
            state.barcodeProductList.data.records = action.payload.data;
            state.barcodeProductList.apiMsg.status = action.meta.requestStatus;

        });
        builder.addCase(getAllProductsAction.pending, (state, action) => {
            state.barcodeProductList.data.records = null;
            state.barcodeProductList.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllProductsAction.rejected, (state, action) => {
            state.barcodeProductList.apiMsg.status = action.meta.requestStatus;
        });

        // getAllProductsAction end


        // getAllInvoiceProductsAction start


        builder.addCase(getAllInvoiceProductsAction.fulfilled, (state, action) => {
            state.invoiceProductList.data.records = action.payload.data;
            state.invoiceProductList.apiMsg.status = action.meta.requestStatus;

        });
        builder.addCase(getAllInvoiceProductsAction.pending, (state, action) => {
            state.invoiceProductList.data.records = null;
            state.invoiceProductList.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllInvoiceProductsAction.rejected, (state, action) => {
            state.invoiceProductList.apiMsg.status = action.meta.requestStatus;
        });

        // getAllInvoiceProductsAction end

        // invoicePromotionCalculationAction start

        builder.addCase(invoicePromotionCalculationAction.fulfilled, (state, action) => {
            state.promotionCalculationData.data = action.payload.data;
            state.promotionCalculationData.apiMsg.status = action.meta.requestStatus;
            state.promotionCalculationData.apiMsg.message = action.payload.message.message;
        });
        builder.addCase(invoicePromotionCalculationAction.pending, (state, action) => {
            state.promotionCalculationData.data = null;
            state.promotionCalculationData.apiMsg.message = action.meta.requestStatus;;
            state.promotionCalculationData.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(invoicePromotionCalculationAction.rejected, (state, action) => {
            state.promotionCalculationData.apiMsg.message = action.error.message;
            state.promotionCalculationData.apiMsg.status = action.meta.requestStatus;
        });

        // invoicePromotionCalculationAction end

    },
});
export const { RESET, ResetCustomerInvoiceDetailsState, ResetInvoiceDocumentRemoveState, ResetInvoiceDocumentUploadState, ResetBarcodeProductsList } = customerInvoicesReducer.actions;

export default customerInvoicesReducer.reducer;

