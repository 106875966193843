import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiCall, apiFileUploadCall } from "../../../API";
import { toast } from "react-toastify";
export const getAllCustomerAction = createAsyncThunk(
  "Customer/GetAllCustomer",
  async (data) => {

    if (data.dataGridDetails) {
      return {
        ...data.dataGridDetails.gridData
      }
    }
    else {
      delete data.dataGridDetails;
      return apiCall(`customer/getall${data.query ? data.query : ""}`, "POST", data, false)
        .then((response) => {
          if (response.status === 200) {
            if (!response.data.hasError) {

              return {
                ...response.data,
              }
            } else {
              //  console.log("response error", response.data.message);
              return Promise.reject({
                ...response.data.message,
              });
            }
          }
        })
        .catch((error) => {
          return Promise.reject({
            ...error,
          });
        });
    }
  }
);

export const getCustomerAction = createAsyncThunk(
  "Customer/GetCustomer",
  async (data) => {
    return apiCall("customer/getcustomer", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {

            return {
              ...response.data,
            }
          } else {
            //  console.log("response error", response.data.message);
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);


export const insertUpdateCustomerAction = createAsyncThunk(
  "Customer/insertupdatecustomer",
  async (data) => {
    // console.log("api action insert",data)
    return apiCall("customer/add", "POST", data, false)
      .then((response) => {
        console.log("api response", response);
        if (response.status === 200) {
          if (!response.data.hasError) {
            // console.log('response.data ',response.data.message)
            let message = response.data.message
            // console.log("message",message);
            return {
              data: {
                ...response.data,
              },
              message: {
                code: response.data.message.code,
                type: response.data.message.type,
                message: response.data.message.message
              },
              newRecord: data.id ? false : true
            }
          } else {
            // console.log("response error", response.data.message);
            if (!response.data.message && response.data.errors && response.data.errors.length) {
              response.data.message = response.data.errors[0];
            }
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
        else {
          return Promise.reject({
            ...response.data,
          });
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);

export const customerActiveInactiveAction = createAsyncThunk(
  'Customer/activeinactive',
  async (data) => {
    return apiCall("customer/activeinactive", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            return {
              ...response.data,
            }
          } else {
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
)

export const getPaymentMethods = createAsyncThunk(
  "Customer/GetAllPaymentMethods",
  async (data) => {
    return apiCall("paymentmethod", "GET", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {

            return {
              ...response.data,
            }
          } else {
            //  console.log("response error", response.data.message);
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);

export const checkPaymentMethodExistAction = createAsyncThunk(
  "Customer/checkExistPaymentMethod",
  async (data) => {
    return apiCall(`paymentmethod?name=${data.name}`, "POST", false)
      .then((response) => {
        if (response.status === 200) {

          return {
            ...response,
          }

        }
        else {
          toast.error("Something went wrong");

        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);

export const addPaymentMethodAction = createAsyncThunk(
  "Customer/addPaymentMethod",
  async (data) => {
    return apiCall("paymentmethod/add", "POST", data, false)
      .then((response) => {
        console.log("res", response);
        if (response.status === 200) {
          if (!response.data.hasError) {
            return {
              ...response.data,
            }
          } else {
            //  console.log("response error", response.data.message);
            return Promise.reject({
              ...response.data.errors[0],
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);

export const addCustomerDocumentAction = createAsyncThunk(
  "Customer/addCustomerDocument",
  async (data) => {
    return apiFileUploadCall("customer/addcustomerdocument", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            return {
              ...response.data,
            }
          } else {
            //  console.log("response error", response.data.message);
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);

export const getCustomerDocumentsAction = createAsyncThunk(
  "Customer/getCustomerDocuments",
  async (data) => {
    return apiCall("customer/getCustomerDocuments", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            return {
              ...response.data,
            }
          } else {
            //  console.log("response error", response.data.message);
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);


export const deleteCustomerDocumentsAction = createAsyncThunk(
  "Customer/removeCustomerDocuments",
  async (data) => {
    return apiCall("customer/removeCustomerDocument", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {

            return {
              ...response.data,
            }
          } else {
            //  console.log("response error", response.data.message);
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);

export const addCustomerNoteAction = createAsyncThunk(
  "Customer/addCustomerNote",
  async (data) => {
    return apiFileUploadCall("customer/addcustomernote", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            return {
              ...response.data,
            }
          } else {
            //  console.log("response error", response.data.message);
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);

export const getCustomerNotesAction = createAsyncThunk(
  "Customer/getCustomerNotes",
  async (data) => {
    return apiCall("customer/getCustomerNotes", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            return {
              ...response.data,
            }
          } else {
            //  console.log("response error", response.data.message);
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);


export const deleteCustomerNoteAction = createAsyncThunk(
  "Customer/removeCustomerNote",
  async (data) => {
    return apiCall("customer/RemoveCustomerNote", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {

            return {
              ...response.data,
            }
          } else {
            //  console.log("response error", response.data.message);
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);




