import { createSlice } from "@reduxjs/toolkit";

// import { getAllQuotationAction, getQuotationAction, insertUpdateQuotationAction, getQuotationDocumentsAction, addQuotationDocumentAction, deleteQuotationDocumentsAction, quotationActiveInactiveAction, getAllProductsAction, getAllInvoiceProductsAction } from "../../actions/customer/proformaInvoiceActions";

import { getAllQuotationAction, getQuotationAction,deleteQuotationAction, insertUpdateQuotationAction, getQuotationDocumentsAction, addQuotationDocumentAction, deleteQuotationDocumentsAction, quotationActiveInactiveAction, getAllProductsAction, getAllInvoiceProductsAction, convertQuotationToInvoiceAction } from "../../actions/customer/quotationActions";


import { toast } from 'react-toastify';

const INITIAL_STATE = {
    quotation: {
        data: {
            records: [],
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    selectedQuotation: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },

    insertUpdateQuotationDetails: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },
    quotationActiveInactiveDetails: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },

    quotationDocuments: {
        data: {
            records: [],
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    quotationDocumentInsertDetails: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    quotationDocumentRemoveDetails: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    barcodeProductList: {
        data: {
            records: [],
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    invoiceProductList: {
        data: {
            records: [],
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    convertQuotationToInvoiceDetails: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null
        }
    },
    deleteQuotationDetails: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null
        }
    }

};

const quotationReducer = createSlice({
    name: "quotationReducer",
    initialState: INITIAL_STATE,
    reducers: {
        RESET(state, action) {
            state = INITIAL_STATE;
        },
        ResetQuotationDetailsState(state, action) {
            state.insertUpdateQuotationDetails = INITIAL_STATE.insertUpdateQuotationDetails;
            state.selectedQuotation = INITIAL_STATE.selectedQuotation;
            state.quotationDocumentInsertDetails = INITIAL_STATE.quotationDocumentInsertDetails;
            state.quotationDocuments = INITIAL_STATE.quotationDocuments;
            state.convertQuotationToInvoiceDetails = INITIAL_STATE.convertQuotationToInvoiceDetails;
            state.quotationActiveInactiveDetails = INITIAL_STATE.quotationActiveInactiveDetails;
        },
        ResetQuotationDocumentUploadState(state, action) {
            state.quotationDocumentInsertDetails = INITIAL_STATE.quotationDocumentInsertDetails;
        },
        ResetQuotationDocumentRemoveState(state, action) {
            state.quotationDocumentRemoveDetails = INITIAL_STATE.quotationDocumentRemoveDetails;
        },
        ResetBarcodeProductsList(state, action) {
            state.barcodeProductList = INITIAL_STATE.barcodeProductList;
            state.invoiceProductList = INITIAL_STATE.invoiceProductList;
        },
        ResetDeleteQuotationDetailsState(state, action) {
            state.deleteQuotationDetails = INITIAL_STATE.deleteQuotationDetails;
        }

    },
    extraReducers: (builder) => {

        //getallcustomeraction start


        builder.addCase(getAllQuotationAction.fulfilled, (state, action) => {
            state.quotation.data.records = action.payload.data;
            state.quotation.apiMsg.status = action.meta.requestStatus;
            //state.customer.apiMsg.message = action.payload.message.message;
        });
        builder.addCase(getAllQuotationAction.pending, (state, action) => {
            state.quotation.data.records = null;
            //state.customer.apiMsg.message = action.meta.requestStatus;;
            state.quotation.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllQuotationAction.rejected, (state, action) => {
            //state.customer.apiMsg.message = action.error.message;
            state.quotation.apiMsg.status = action.meta.requestStatus;
        });
        //getallcustomeraction end

        //getcustomeraction start

        builder.addCase(getQuotationAction.fulfilled, (state, action) => {
            state.selectedQuotation.data = action.payload.data;
            state.selectedQuotation.apiMsg.status = action.meta.requestStatus;
            state.selectedQuotation.apiMsg.message = action.payload.message.message;
        });
        builder.addCase(getQuotationAction.pending, (state, action) => {
            //console.log("getCustomerAction pending", action);
            state.selectedQuotation.data = null;
            state.selectedQuotation.apiMsg.message = action.meta.requestStatus;;
            state.selectedQuotation.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getQuotationAction.rejected, (state, action) => {
            //console.log("getCustomerAction rejected", action);
            state.selectedQuotation.apiMsg.message = action.error.message;
            state.selectedQuotation.apiMsg.status = action.meta.requestStatus;
        });

        // getcustomeraction end


        // insert update insertUpdateCustomerAction Action start

        builder.addCase(
            insertUpdateQuotationAction.fulfilled,
            (state, action) => {
                // console.log("insertUpdateCustomerAction", action.payload);

                state.insertUpdateQuotationDetails.data = action.payload.data.data;
                state.insertUpdateQuotationDetails.apiMsg.status = action.meta.requestStatus;
                state.insertUpdateQuotationDetails.apiMsg.message = action.payload.message.message;

                if (!action.payload.newRecord) {
                    let updatedRecord = state.quotation.data.records.map(x => {
                        if (x.id == action.payload.data.data.id) {
                            return action.payload.data.data;
                        }
                        else {
                            return x;
                        }
                    })

                    state.quotation.data.records = updatedRecord;
                }
                else {
                    if (state.quotation.data.records.length) {
                        state.quotation.data.records.unshift(action.payload.data.data);
                    }
                    else {
                        state.quotation.data.records.push(action.payload.data.data);
                    }
                }
                toast.success(action.payload.message.message, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "dept-error"
                });

            }
        );
        builder.addCase(
            insertUpdateQuotationAction.pending,
            (state, action) => {
                //console.log("insertUpdateCustomerAction pending", action);
                state.insertUpdateQuotationDetails.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            insertUpdateQuotationAction.rejected,
            (state, action) => {
                //   console.log("insertUpdateVatAction rejected", action);
                //   console.log(action.error.message);
                state.insertUpdateQuotationDetails.apiMsg.message = action.error.message;
                state.insertUpdateQuotationDetails.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "dept-error"
                });
            }
        );


        //   insert update insertUpdateDetails action end



        // activeinactive customer action start

        builder.addCase(
            quotationActiveInactiveAction.fulfilled,
            (state, action) => {

                state.quotationActiveInactiveDetails.data = action.payload.data;
                state.quotationActiveInactiveDetails.apiMsg.status = action.meta.requestStatus;
                state.quotationActiveInactiveDetails.apiMsg.message = action.payload.message.message;

                let updatedRecord = state.quotation.data.records.map(x => {

                    if (x.id == action.meta.arg.id) {
                        x.isActive = action.meta.arg.isActive;
                        return x;
                    }
                    else {
                        return x;
                    }
                })



                state.quotation.data.records = updatedRecord;

                toast.success(action.payload.message.message, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "success"
                });

            }
        );
        builder.addCase(
            quotationActiveInactiveAction.pending,
            (state, action) => {
                state.quotationActiveInactiveDetails.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            quotationActiveInactiveAction.rejected,
            (state, action) => {
                state.quotationActiveInactiveDetails.apiMsg.message = action.error.message;
                state.quotationActiveInactiveDetails.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "error"
                });
            }
        );

        // activeinactive customer action end



        // getcustomerDocuments start

        builder.addCase(getQuotationDocumentsAction.fulfilled, (state, action) => {
            state.quotationDocuments.data.records = action.payload.data;
            state.quotationDocuments.apiMsg.status = action.meta.requestStatus;
            state.quotationDocuments.apiMsg.message = action.payload.message.message;
        });
        builder.addCase(getQuotationDocumentsAction.pending, (state, action) => {
            state.quotationDocuments.data.records = null;
            state.quotationDocuments.apiMsg.message = action.meta.requestStatus;;
            state.quotationDocuments.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getQuotationDocumentsAction.rejected, (state, action) => {
            state.quotationDocuments.apiMsg.message = action.error.message;
            state.quotationDocuments.apiMsg.status = action.meta.requestStatus;
        });

        // getcustomerDocuments end

        // removecustomerDocument start
        builder.addCase(deleteQuotationDocumentsAction.fulfilled, (state, action) => {
            state.quotationDocumentRemoveDetails.data = action.payload.data;
            state.quotationDocumentRemoveDetails.apiMsg.status = action.meta.requestStatus;
            state.quotationDocumentRemoveDetails.apiMsg.message = action.payload.message.message;
            state.quotationDocuments.data.records = state.quotationDocuments.data.records.filter(doc => doc.id !== action.meta.arg.id)

            toast.success(action.payload.message.message, {
                position: toast.POSITION.BOTTOM_CENTER,
            });
        });
        builder.addCase(deleteQuotationDocumentsAction.pending, (state, action) => {
            state.quotationDocumentRemoveDetails.data = null;
            state.quotationDocumentRemoveDetails.apiMsg.message = action.meta.requestStatus;;
            state.quotationDocumentRemoveDetails.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(deleteQuotationDocumentsAction.rejected, (state, action) => {
            state.quotationDocumentRemoveDetails.apiMsg.message = action.error.message;
            state.quotationDocumentRemoveDetails.apiMsg.status = action.meta.requestStatus;
        });

        // removecustomerDocument end

        // addcustomerdocument start

        builder.addCase(addQuotationDocumentAction.fulfilled, (state, action) => {
            state.quotationDocumentInsertDetails.data = action.payload.data;
            state.quotationDocumentInsertDetails.apiMsg.status = action.meta.requestStatus;
            state.quotationDocumentInsertDetails.apiMsg.message = action.payload.message.message;

            if (state.quotationDocuments.data.records.length) {
                state.quotationDocuments.data.records.unshift(action.payload.data);
            }
            else {
                state.quotationDocuments.data.records.push(action.payload.data);
            }

            toast.success(action.payload.message.message, {
                position: toast.POSITION.BOTTOM_CENTER,
            });
        });
        builder.addCase(addQuotationDocumentAction.pending, (state, action) => {
            state.quotationDocumentInsertDetails.data = null;
            state.quotationDocumentInsertDetails.apiMsg.message = action.meta.requestStatus;;
            state.quotationDocumentInsertDetails.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(addQuotationDocumentAction.rejected, (state, action) => {
            state.quotationDocumentInsertDetails.apiMsg.message = action.error.message;
            state.quotationDocumentInsertDetails.apiMsg.status = action.meta.requestStatus;
            toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                position: toast.POSITION.BOTTOM_CENTER,

            });
        });

        // addcustomerdocument end

        // getall products action start

        builder.addCase(getAllProductsAction.fulfilled, (state, action) => {
            state.barcodeProductList.data.records = action.payload.data;
            state.barcodeProductList.apiMsg.status = action.meta.requestStatus;

        });
        builder.addCase(getAllProductsAction.pending, (state, action) => {
            state.barcodeProductList.data.records = null;
            state.barcodeProductList.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllProductsAction.rejected, (state, action) => {
            state.barcodeProductList.apiMsg.status = action.meta.requestStatus;
        });

        // getall products action end


        // getall invoice products action start


        builder.addCase(getAllInvoiceProductsAction.fulfilled, (state, action) => {
            state.invoiceProductList.data.records = action.payload.data;
            state.invoiceProductList.apiMsg.status = action.meta.requestStatus;

        });
        builder.addCase(getAllInvoiceProductsAction.pending, (state, action) => {
            state.invoiceProductList.data.records = null;
            state.invoiceProductList.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllInvoiceProductsAction.rejected, (state, action) => {
            state.invoiceProductList.apiMsg.status = action.meta.requestStatus;
        });
        // getall invoice products action end

        // convert quotationtoinvoice action start


        builder.addCase(convertQuotationToInvoiceAction.fulfilled, (state, action) => {
            state.convertQuotationToInvoiceDetails.data = action.payload.data;
            state.convertQuotationToInvoiceDetails.apiMsg.status = action.meta.requestStatus;
            state.convertQuotationToInvoiceDetails.apiMsg.message = action.payload.message.message;
            state.quotation.data.records = state.quotation.data.records.filter(doc => doc.id !== action.meta.arg.id)

            toast.success(action.payload.message.message, {
                position: toast.POSITION.BOTTOM_CENTER,
            });
        });
        builder.addCase(convertQuotationToInvoiceAction.pending, (state, action) => {
            state.convertQuotationToInvoiceDetails.data = null;
            state.convertQuotationToInvoiceDetails.apiMsg.message = action.meta.requestStatus;;
            state.convertQuotationToInvoiceDetails.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(convertQuotationToInvoiceAction.rejected, (state, action) => {
            state.convertQuotationToInvoiceDetails.apiMsg.message = action.error.message;
            state.convertQuotationToInvoiceDetails.apiMsg.status = action.meta.requestStatus;
        });

        // convert quotationtoinvoice action end



        // delete invoice action start

        builder.addCase(deleteQuotationAction.fulfilled, (state, action) => {
            state.deleteQuotationDetails.data = action.payload.data;
            state.deleteQuotationDetails.apiMsg.status = action.meta.requestStatus;
            state.deleteQuotationDetails.apiMsg.message = action.payload.message.message;
            state.quotation.data.records = state.quotation.data.records.filter(doc => doc.id !== action.meta.arg.id)
            toast.success(action.payload.message.message, {
                position: toast.POSITION.BOTTOM_CENTER,
            });
        });
        builder.addCase(deleteQuotationAction.pending, (state, action) => {
            state.deleteQuotationDetails.data = null;
            state.deleteQuotationDetails.apiMsg.message = action.meta.requestStatus;;
            state.deleteQuotationDetails.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(deleteQuotationAction.rejected, (state, action) => {
            state.deleteQuotationDetails.apiMsg.message = action.error.message;
            state.deleteQuotationDetails.apiMsg.status = action.meta.requestStatus;
        });

        // delete invoice action end


    },
});
export const { RESET, ResetQuotationDetailsState, ResetQuotationDocumentRemoveState, ResetQuotationDocumentUploadState, ResetBarcodeProductsList, ResetDeleteQuotationDetailsState } = quotationReducer.actions;

export default quotationReducer.reducer;

