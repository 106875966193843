import { createSlice } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';

import { getAllCreditNotesAction, insertUpdateCreditNoteAction, activeInactiveCreditNoteAction, addCreditNoteDocumentAction, getCreditNoteDocumentsAction, deleteCreditNoteDocumentsAction, getCreditNoteAction, getAllSupplierAction, getPaymentMethods,  } from "../../actions/supplier/supplierCreditNoteActions";

const INITIAL_STATE = {
    creditNotes: {
        data: {
            records: [],
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    insertUpdateCreditNoteDetails: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },
    selectedCreditNote: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    activeInactiveDetails: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },
    creditNoteDocuments: {
        data: {
            records: [],
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    creditNoteDocumentInsertDetails: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    creditNoteDocumentRemoveDetails: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    paymentMethod: {
        data: {
          records: [],
          totalRecords: 0,
        },
        apiMsg: {
          message: null,
          status: null,
          type: null,
        },
      },
      supplier: {
        data: {
          records: [],
          totalRecords: 0,
        },
        apiMsg: {
          message: null,
          status: null,
          type: null,
        },
      }
};

const creditNoteReducer = createSlice({
    name: "creditNotes",
    initialState: INITIAL_STATE,
    reducers: {
        RESET(state, action) {
            state = INITIAL_STATE;
        },
        ResetCreditNotesState(state, action) {
            state.insertUpdateCreditNoteDetails = INITIAL_STATE.insertUpdateCreditNoteDetails;
            state.selectedCreditNote = INITIAL_STATE.selectedCreditNote;
            state.creditNoteDocuments = INITIAL_STATE.creditNoteDocuments;
            state.activeInactiveDetails = INITIAL_STATE.activeInactiveDetails;
        },
        ResetCreditNotesDocumentRemoveState(state, action) {
            state.creditNoteDocumentRemoveDetails = INITIAL_STATE.creditNoteDocumentRemoveDetails;
        },
        ResetCreditNotesDocumentUploadState(state, action) {
            state.creditNoteDocumentInsertDetails = INITIAL_STATE.creditNoteDocumentInsertDetails;
        },
    },
    extraReducers: (builder) => {

        //  -------- getAll CreditNotesAction start--------------------

        builder.addCase(getAllCreditNotesAction.fulfilled, (state, action) => {

            state.creditNotes.data.records = action.payload.data;
            state.creditNotes.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllCreditNotesAction.pending, (state, action) => {
            state.creditNotes.data.records = null;
            state.creditNotes.apiMsg.message = action.meta.requestStatus;;
            state.creditNotes.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllCreditNotesAction.rejected, (state, action) => {
            state.creditNotes.apiMsg.message = action.error.message;
            state.creditNotes.apiMsg.status = action.meta.requestStatus;
        });



        //  -------- getAll CreditNotesAction end--------------------


        // -------- insertUpdateCreditNotesAction start--------------------

        builder.addCase(
            insertUpdateCreditNoteAction.fulfilled,
            (state, action) => {

                state.insertUpdateCreditNoteDetails.data = action.payload.data.data;
                state.insertUpdateCreditNoteDetails.apiMsg.status = action.meta.requestStatus;
                state.insertUpdateCreditNoteDetails.apiMsg.message = action.payload.message.message;
                if (!action.payload.newRecord) {
                  let updatedRecord = state.creditNotes.data.records.map(x => {
                    if (x.id == action.payload.data.data.id) {
                      return action.payload.data.data;
                    }
                    else {
                      return x;
                    }
                  })
        
                  state.creditNotes.data.records = updatedRecord;
                }
                else {
                  if (state.creditNotes.data.records.length) {
                    state.creditNotes.data.records.unshift(action.payload.data.data);
                  }
                  else {
                    state.creditNotes.data.records.push(action.payload.data.data);
                  }
                }
                toast.success(action.payload.message.message, {
                  position: toast.POSITION.BOTTOM_CENTER,
                  toastId: "dept-error"
                });
        

            }
        );
        builder.addCase(
            insertUpdateCreditNoteAction.pending,
            (state, action) => {
                state.insertUpdateCreditNoteDetails.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            insertUpdateCreditNoteAction.rejected,
            (state, action) => {
                state.insertUpdateCreditNoteDetails.apiMsg.message = action.error.message;
                state.insertUpdateCreditNoteDetails.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "dept-error"
                });
            }
        );


        // -------- insertUpdateCreditNotesAction end--------------------


        //------------------------------- getCreditNote start -------------------------

        builder.addCase(getCreditNoteAction.fulfilled, (state, action) => {
            state.selectedCreditNote.data = action.payload.data;
            state.selectedCreditNote.apiMsg.status = action.meta.requestStatus;
            state.selectedCreditNote.apiMsg.message = action.payload.message.message;
        });

        builder.addCase(getCreditNoteAction.pending, (state, action) => {
            state.selectedCreditNote.data = null;
            state.selectedCreditNote.apiMsg.message = action.meta.requestStatus;;
            state.selectedCreditNote.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(getCreditNoteAction.rejected, (state, action) => {
            state.selectedCreditNote.apiMsg.message = action.error.message;
            state.selectedCreditNote.apiMsg.status = action.meta.requestStatus;
        });


        //------------------------------- getCreditNote+ end -------------------------

        //------------------------------- activeInactiveAction start -------------------------

        builder.addCase(
            activeInactiveCreditNoteAction.fulfilled,
            (state, action) => {

                state.activeInactiveDetails.data = action.payload.data.data;
                state.activeInactiveDetails.apiMsg.status = action.meta.requestStatus;
                state.activeInactiveDetails.apiMsg.message = action.payload.message.message;

                // let updatedRecord = state.supplier.data.records.map(x => {
                //     if (x.id == action.meta.arg.id) {
                //         x.isActive = action.meta.arg.isActive;
                //         return x;
                //     }
                //     else {
                //         return x;
                //     }
                // })



                // state.supplier.data.records = updatedRecord;

                toast.success(action.payload.message.message, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "success"
                });

            }
        );
        builder.addCase(
            activeInactiveCreditNoteAction.pending,
            (state, action) => {
                state.activeInactiveDetails.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            activeInactiveCreditNoteAction.rejected,
            (state, action) => {
                state.activeInactiveDetails.apiMsg.message = action.error.message;
                state.activeInactiveDetails.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "error"
                });
            }
        );

        //------------------------------- activeInactiveAction end -------------------------

        //------------------------------- getcreditNoteDocumentsAction start -------------------------

        builder.addCase(getCreditNoteDocumentsAction.fulfilled, (state, action) => {
            state.creditNoteDocuments.data.records = action.payload.data;
            state.creditNoteDocuments.apiMsg.status = action.meta.requestStatus;
            state.creditNoteDocuments.apiMsg.message = action.payload.message.message;
        });
        builder.addCase(getCreditNoteDocumentsAction.pending, (state, action) => {
            state.creditNoteDocuments.data.records = null;
            state.creditNoteDocuments.apiMsg.message = action.meta.requestStatus;;
            state.creditNoteDocuments.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getCreditNoteDocumentsAction.rejected, (state, action) => {
            state.creditNoteDocuments.apiMsg.message = action.error.message;
            state.creditNoteDocuments.apiMsg.status = action.meta.requestStatus;
        });

        //------------------------------- getcreditNoteDocumentsAction end -------------------------

        //------------------------------- deleteCreditNoteDocumentAction start -------------------------


        builder.addCase(deleteCreditNoteDocumentsAction.fulfilled, (state, action) => {
            state.creditNoteDocumentRemoveDetails.data = action.payload.data;
            state.creditNoteDocumentRemoveDetails.apiMsg.status = action.meta.requestStatus;
            state.creditNoteDocumentRemoveDetails.apiMsg.message = action.payload.message.message;
            state.creditNoteDocuments.data.records = state.creditNoteDocuments.data.records.filter(doc => doc.id !== action.meta.arg.id)
            toast.success(action.payload.message.message, {
                position: toast.POSITION.BOTTOM_CENTER,
            });
        });
        builder.addCase(deleteCreditNoteDocumentsAction.pending, (state, action) => {
            state.creditNoteDocumentRemoveDetails.data = null;
            state.creditNoteDocumentRemoveDetails.apiMsg.message = action.meta.requestStatus;;
            state.creditNoteDocumentRemoveDetails.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(deleteCreditNoteDocumentsAction.rejected, (state, action) => {
            state.creditNoteDocumentRemoveDetails.apiMsg.message = action.error.message;
            state.creditNoteDocumentRemoveDetails.apiMsg.status = action.meta.requestStatus;
        });

        //------------------------------- deleteCreditNoteDocumentAction end -------------------------


        //------------------------------- AddCreditNoteDocumentAction start -------------------------


        builder.addCase(addCreditNoteDocumentAction.fulfilled, (state, action) => {
            state.creditNoteDocumentInsertDetails.data = action.payload.data;
            state.creditNoteDocumentInsertDetails.apiMsg.status = action.meta.requestStatus;
            state.creditNoteDocumentInsertDetails.apiMsg.message = action.payload.message.message;

            if (state.creditNoteDocuments.data.records.length) {
                state.creditNoteDocuments.data.records.unshift(action.payload.data);
            }
            else {
                state.creditNoteDocuments.data.records.push(action.payload.data);
            }

            toast.success(action.payload.message.message, {
                position: toast.POSITION.BOTTOM_CENTER,
            });
        });
        builder.addCase(addCreditNoteDocumentAction.pending, (state, action) => {
            state.creditNoteDocumentInsertDetails.data = null;
            state.creditNoteDocumentInsertDetails.apiMsg.message = action.meta.requestStatus;;
            state.creditNoteDocumentInsertDetails.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(addCreditNoteDocumentAction.rejected, (state, action) => {
            state.creditNoteDocumentInsertDetails.apiMsg.message = action.error.message;
            state.creditNoteDocumentInsertDetails.apiMsg.status = action.meta.requestStatus;
            toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                position: toast.POSITION.BOTTOM_CENTER,

            });
        });

        //------------------------------- AddCreditNoteDocumentAction end -------------------------


                  // getallpaymentmethod start


    builder.addCase(getPaymentMethods.fulfilled, (state, action) => {
        state.paymentMethod.data.records = action.payload.data;
        state.paymentMethod.apiMsg.status = action.meta.requestStatus;
        state.paymentMethod.apiMsg.message = action.payload.message.message;
      });
      builder.addCase(getPaymentMethods.pending, (state, action) => {
        state.paymentMethod.data.records = null;
        state.paymentMethod.apiMsg.message = action.meta.requestStatus;;
        state.paymentMethod.apiMsg.status = action.meta.requestStatus;
      });
      builder.addCase(getPaymentMethods.rejected, (state, action) => {
        state.paymentMethod.apiMsg.message = action.error.message;
        state.paymentMethod.apiMsg.status = action.meta.requestStatus;
      });
  
  
      // getallpaymentmethod end

        //  -------- getAll Supplier Action start--------------------

    builder.addCase(getAllSupplierAction.fulfilled, (state, action) => {
        // console.log("getAllSupplierAction fulfilled", action);
  
        state.supplier.data.records = action.payload.data;
        state.supplier.apiMsg.status = action.meta.requestStatus;
      });
      builder.addCase(getAllSupplierAction.pending, (state, action) => {
        // console.log("getAllSupplierAction pending", action);
        state.supplier.data.records = null;
        state.supplier.apiMsg.message = action.meta.requestStatus;;
        state.supplier.apiMsg.status = action.meta.requestStatus;
      });
      builder.addCase(getAllSupplierAction.rejected, (state, action) => {
        // console.log("getAllSupplierAction rejected", action);
        state.supplier.apiMsg.message = action.error.message;
        state.supplier.apiMsg.status = action.meta.requestStatus;
      });
  
  
  
      //  -------- getAll Supplier Action end--------------------
    },
});
export const { RESET, ResetCreditNotesState, ResetCreditNotesDocumentRemoveState, ResetCreditNotesDocumentUploadState } = creditNoteReducer.actions;
export default creditNoteReducer.reducer;

