import { createSlice } from "@reduxjs/toolkit";
import { getAllTransactionCategoryAction, insertUpdateTransactionCategoryAction } from "../../actions/manage-settings/transactionCategoryActions";
import { toast } from 'react-toastify';
const INITIAL_STATE = {
    transactionCategories: {
        data: {
            records: [],
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    insertUpdateTransactionCategoryDetails: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },


};

const TransactionCategoryReducer = createSlice({
    name: "TransactionCategory",
    initialState: INITIAL_STATE,
    reducers: {

        RESET(state, action) {
            state = INITIAL_STATE;
        },
        ResetTransactionCategoryState(state, action) {
            state.insertUpdateTransactionCategoryDetails = INITIAL_STATE.insertUpdateTransactionCategoryDetails;
        },
    },
    extraReducers: (builder) => {
        //------------------------------- getalltransactioncategory start -------------------------
        builder.addCase(getAllTransactionCategoryAction.fulfilled, (state, action) => {
            state.transactionCategories.data.records = action.payload.data;
            state.transactionCategories.apiMsg.status = action.meta.requestStatus;
            state.transactionCategories.apiMsg.message = action.payload.message.message;
        });
        builder.addCase(getAllTransactionCategoryAction.pending, (state, action) => {
            state.transactionCategories.data.records = null;
            state.transactionCategories.apiMsg.message = action.meta.requestStatus;;
            state.transactionCategories.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllTransactionCategoryAction.rejected, (state, action) => {
            state.transactionCategories.apiMsg.message = action.error.message;
            state.transactionCategories.apiMsg.status = action.meta.requestStatus;
        });
        //------------------------------- getalltransactioncategory end -------------------------

        //------------------------------- insertupdate transactioncategory start -------------------------

        builder.addCase(
            insertUpdateTransactionCategoryAction.fulfilled,
            (state, action) => {

                state.insertUpdateTransactionCategoryDetails.data = action.payload.data.data;
                state.insertUpdateTransactionCategoryDetails.apiMsg.status = action.meta.requestStatus;
                state.insertUpdateTransactionCategoryDetails.apiMsg.message = action.payload.message.message;

                if (!action.payload.newRecord) {
                    let updatedRecord = state.transactionCategories.data.records.map(x => {
                        if (x.id == action.payload.data.data.id) {
                            return action.payload.data.data;
                        }
                        else {
                            return x;
                        }
                    })

                    state.transactionCategories.data.records = updatedRecord;
                }
                else {
                    if (state.transactionCategories.data.records.length) {
                        state.transactionCategories.data.records.unshift(action.payload.data.data);
                    }
                    else {
                        state.transactionCategories.data.records.push(action.payload.data.data);
                    }
                }


                toast.success(action.payload.message.message, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "dept-error"
                });

            }
        );
        builder.addCase(
            insertUpdateTransactionCategoryAction.pending,
            (state, action) => {
                state.insertUpdateTransactionCategoryDetails.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            insertUpdateTransactionCategoryAction.rejected,
            (state, action) => {
                console.log(action.error.message);
                state.insertUpdateTransactionCategoryDetails.apiMsg.message = action.error.message;
                state.insertUpdateTransactionCategoryDetails.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "dept-error"
                });
            }
        );

        //------------------------------- insertupdate transactioncategory end -------------------------



    },
});

export const { RESET, ResetTransactionCategoryState } = TransactionCategoryReducer.actions;
export default TransactionCategoryReducer.reducer;
