import { createSlice } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import { getAllSupplierStatementsAction, getSupplierStatementsAction, insertUpdateSupplierStatementAction } from "../../actions/supplier/supplierStatementActions";

const INITIAL_STATE = {
  supplierStatements: {
    data: {
      records: [],
      totalRecords: 0,
    },
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },
  selectedSupplierStatements: {
    data: null,
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },

  insertUpdateDetails: {
    data: {},

    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  }
}

const supplierStatementsReducer = createSlice({
  name: "supplierStatements",
  initialState: INITIAL_STATE,
  reducers: {
    RESET(state, action) {
      state = INITIAL_STATE;
    },
    ResetSupplierStatementsState(state, action) {
      state.insertUpdateDetails = INITIAL_STATE.insertUpdateDetails;
      state.selectedSupplierStatements = INITIAL_STATE.selectedSupplierStatements;
    },

  },
  extraReducers: (builder) => {

    //  -------- getAll getAllSupplierStatementsAction Action start--------------------

    builder.addCase(getAllSupplierStatementsAction.fulfilled, (state, action) => {
      // console.log("getAllSupplierStatementsAction fulfilled", action);

      state.supplierStatements.data.records = action.payload.data;
      state.supplierStatements.apiMsg.status = action.meta.requestStatus;
    });
    builder.addCase(getAllSupplierStatementsAction.pending, (state, action) => {
      // console.log("getAllSupplierStatementsAction pending", action);
      state.supplierStatements.data.records = null;
      state.supplierStatements.apiMsg.message = action.meta.requestStatus;;
      state.supplierStatements.apiMsg.status = action.meta.requestStatus;
    });
    builder.addCase(getAllSupplierStatementsAction.rejected, (state, action) => {
      // console.log("getAllSupplierStatementsAction rejected", action);
      state.supplierStatements.apiMsg.message = action.error.message;
      state.supplierStatements.apiMsg.status = action.meta.requestStatus;
    });



    //  -------- getAll getAllSupplierStatementsAction Action end--------------------


    //  -------- getSupplierStatementsAction start--------------------


    builder.addCase(getSupplierStatementsAction.fulfilled, (state, action) => {

      state.selectedSupplierStatements.data = action.payload.data;
      state.selectedSupplierStatements.apiMsg.status = action.meta.requestStatus;

      state.selectedSupplierStatements.apiMsg.status = action.meta.requestStatus;
    });
    builder.addCase(getSupplierStatementsAction.pending, (state, action) => {
      state.selectedSupplierStatements.data = null;
      state.selectedSupplierStatements.apiMsg.message = action.meta.requestStatus;;
      state.selectedSupplierStatements.apiMsg.status = action.meta.requestStatus;
    });
    builder.addCase(getSupplierStatementsAction.rejected, (state, action) => {
      state.selectedSupplierStatements.apiMsg.message = action.error.message;
      state.selectedSupplierStatements.apiMsg.status = action.meta.requestStatus;
    });

    //  -------- getSupplierStatementsAction end--------------------

    // insertupdatesupplierstatementaction start

    builder.addCase(
      insertUpdateSupplierStatementAction.fulfilled,
      (state, action) => {

        state.insertUpdateDetails.data = action.payload.data.data;
        state.insertUpdateDetails.apiMsg.status = action.meta.requestStatus;
        state.insertUpdateDetails.apiMsg.message = action.payload.message.message;


        toast.success(action.payload.message.message, {
          position: toast.POSITION.BOTTOM_CENTER,
          toastId: "dept-error"
        });

      }
    );
    builder.addCase(
      insertUpdateSupplierStatementAction.pending,
      (state, action) => {
        state.insertUpdateDetails.apiMsg.status = action.meta.requestStatus;
      }
    );
    builder.addCase(
      insertUpdateSupplierStatementAction.rejected,
      (state, action) => {

        state.insertUpdateDetails.apiMsg.message = action.error.message;
        state.insertUpdateDetails.apiMsg.status = action.meta.requestStatus;

        toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
          position: toast.POSITION.BOTTOM_CENTER,
          toastId: "dept-error"
        });
      }
    );
    // insertupdatesupplierstatementaction start


  },
});



export const { RESET, ResetSupplierStatementsState } = supplierStatementsReducer.actions;
export default supplierStatementsReducer.reducer;


