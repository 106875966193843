import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiCall, apiFileUploadCall } from "../../../API";
import { toast } from "react-toastify";
export const getAllProductsAction = createAsyncThunk(
  "Product/product",
  async (data) => {
    return apiCall(`product/getall${data.query ? data.query : ""}`, "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            //console.log('response.data ',response.data)
            return {
              ...response.data,
            }
          } else {
            console.log("response error", response.data.message);
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);
export const getProductsAction = createAsyncThunk(
  "Product/getproduct",
  async (data) => {
    return apiCall("Product/getproduct", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            //console.log('response.data ',response.data)
            return {
              ...response.data,
            }
          } else {
            console.log("response error", response.data.message);
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);
export const getProductQtyHistoryAction = createAsyncThunk(
  "Product/GetProductQtyHistory",
  async (data) => {
    return apiCall("Product/GetProductQtyHistory", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            //console.log('response.data ',response.data)
            return {
              ...response.data,
            }
          } else {
            console.log("response error", response.data.message);
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);
export const insertUpdateProductAction = createAsyncThunk(
  "Product/insertupdateProduct",
  async (data) => {
    return apiCall("Product/add", "POST", data, false)
      .then((response) => {
        // console.log(response);
        if (response.status === 200) {
          if (!response.data.hasError) {
            //console.log('response.data ',response.data.message)
            let message = response.data.message
            return {
              data: {
                ...response.data,
              },
              message: {
                code: response.data.message.code,
                type: response.data.message.type,
                message: response.data.message.message
              },
              newRecord: data.id ? false : true
            }
          } else {
            console.log("response error", response.data.message);
            if (!response.data.message && response.data.errors && response.data.errors.length) {
              response.data.message = response.data.errors[0];
            }
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
        else {
          return Promise.reject({
            ...response.data,
          });
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);
export const activeInactiveAction = createAsyncThunk(
  'Product/activeinactive',
  async (data) => {
    return apiCall("Product/activeinactive", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            return {
              ...response.data,
            }
          } else {
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
)
export const getMappedProductAction = createAsyncThunk(
  "Product/getmappedproduct",
  async (data) => {
    return apiCall("supplierproduct/getmapped?productId=" + data.productId + "&productBarcode=" + data.productBarcode, "GET", {}, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            //console.log('response.data ',response.data)
            return {
              ...response.data,
            }
          } else {
            console.log("response error", response.data.message);
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);
export const getCostProductAction = createAsyncThunk(
  "Product/getcostproduct",
  async (data) => {
    console.log("COSTAction", data)
    return apiCall("supplierproductcosthistory?productId=" + data.productId, "GET", {}, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            //console.log('response.data ',response.data)
            return {
              ...response.data,
            }
          } else {
            console.log("response error", response.data.message);
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);
export const getPriceProductAction = createAsyncThunk(
  "Product/getPriceproduct",
  async (data) => {
    // console.log("pricedata", data);
    return apiCall("productpricehistory?productId=" + data.productId, "GET", {}, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            // console.log('response.data ',response.data)
            return {
              ...response.data,
            }
          } else {
            console.log("response error", response.data.message);
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);

export const getProductComparisonAction = createAsyncThunk(
  "Product/GetProductComparison",
  async (data) => {
    return apiCall("product/getproductcomparison?id=" + data.id, "POST", null, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            return {
              ...response.data,
            }
          } else {
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);

export const productApproveAction = createAsyncThunk(
  "Product/Approve",
  async (data) => {
    return apiCall("product/approve?id=" + data.id, "POST", null, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            return {
              ...response.data,
            }
          } else {
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);

export const productRejectAction = createAsyncThunk(
  "Product/Reject",
  async (data) => {
    return apiCall("product/reject?id=" + data.id, "POST", null, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            return {
              ...response.data,
            }
          } else {
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);
export const getProductBarcodeAction = createAsyncThunk(
  "Product/GetBarcode",
  async (data) => {
    return apiCall("Product/GenerateCustomBarcode", "GET", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {

            return {
              ...response.data,
            }
          } else {
            //  console.log("response error", response.data.message);
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);

export const uploadProductAction = createAsyncThunk(
  "Product/uploadproduct",
  async (data) => {
    return apiFileUploadCall("Product/UploadProduct", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            return {
              ...response.data,
            }
          } else {
            console.log("response error", response.data.message);
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);

export const updateTempProductAction = createAsyncThunk(
  "Product/updateTempProduct",
  async (data) => {
    return apiCall("Product/UpdateTempProduct", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {

          toast.success(response.data.message.message, {
            position: toast.POSITION.BOTTOM_CENTER,

          });
          if (!response.data.hasError) {
            return {
              ...response.data,
            }
          } else {
            toast.error(response.data.message, {
              position: toast.POSITION.BOTTOM_CENTER,

            });
            console.log("response error", response.data.message);
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);

export const GetAllTempProductAction = createAsyncThunk(
  "Product/GetAllTempProduct",
  async (data) => {
    return apiCall(`Product/GetAllTempProduct${data.query ? data.query : ""}`, "POST", data, false)
      .then((response) => {
        if (response.status === 200) {

          if (!response.data.hasError) {
            return {
              ...response.data,
            }
          } else {
            toast.error(response.data.message, {
              position: toast.POSITION.BOTTOM_CENTER,

            });
            console.log("response error", response.data.message);
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);

export const ImportTempProductAction = createAsyncThunk(
  "Product/ImportProduct",
  async (data) => {
    return apiCall(`Product/ImportProduct?businessId=${data.businessID}`, "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          toast.success(response.data.message.message, {
            position: toast.POSITION.BOTTOM_CENTER,

          });
          if (!response.data.hasError) {
            return {
              ...response.data,
            }
          } else {
            toast.error(response.data.message, {
              position: toast.POSITION.BOTTOM_CENTER,

            });
            console.log("response error", response.data.message);
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);