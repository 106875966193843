import { createSlice } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import { getAllProductOutOfDate } from "../../../actions/reports/stock-reports/productOutOfDateAction";

const INITIAL_STATE = {
    productOutOfDateReport: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    }
};

const productOutOfDateReducer = createSlice({
    name: "ProductOutOfDate",
    initialState: INITIAL_STATE,
    reducers: {
        RESET(state, action) {
            state.productOutOfDateReport = INITIAL_STATE.productOutOfDateReport;
        }
    },
    extraReducers: (builder) => {
        //------------------------------- getAllProductOutOfDate start -------------------------
        
        builder.addCase(getAllProductOutOfDate.fulfilled, (state, action) => {
            state.productOutOfDateReport.data = action.payload.data;
            state.productOutOfDateReport.apiMsg.status = action.meta.requestStatus;
            state.productOutOfDateReport.apiMsg.message = action.payload.message.message;
        });
            builder.addCase(getAllProductOutOfDate.pending, (state, action) => {
                state.productOutOfDateReport.apiMsg.status = action.meta.requestStatus;
            });
            builder.addCase(getAllProductOutOfDate.rejected, (state, action) => {
                state.productOutOfDateReport.apiMsg.message = action.error.message;
                state.productOutOfDateReport.apiMsg.status = action.meta.requestStatus;
            });
        //------------------------------- getAllProductOutOfDate end -------------------------
    }
});

export const { RESET } = productOutOfDateReducer.actions;
export default productOutOfDateReducer.reducer;
