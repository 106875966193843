import { createSlice } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import { getAllGroupMappingTablesAction, insertUpdateGroupMappingTableAction } from "../../actions/manage-settings/syncUtilityActions";
const INITIAL_STATE = {
    groupMappingTables: {
        data: {
            records: [],
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    insertUpdateGroupMappingTableDetails: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },

    },


};

const SyncUtilityReducer = createSlice({
    name: "SyncUtility",
    initialState: INITIAL_STATE,
    reducers: {

        RESET(state, action) {
            state = INITIAL_STATE;
        },
        ResetSyncUtilityDetails(state, action) {
            state.insertUpdateGroupMappingTableDetails = INITIAL_STATE.insertUpdateGroupMappingTableDetails;
        },
    },
    extraReducers: (builder) => {
        //------------------------------- getallgroupmappingtable start -------------------------
        builder.addCase(getAllGroupMappingTablesAction.fulfilled, (state, action) => {
            state.groupMappingTables.data.records = action.payload.data;
            state.groupMappingTables.apiMsg.status = action.meta.requestStatus;
            state.groupMappingTables.apiMsg.message = action.payload.message.message;
        });
        builder.addCase(getAllGroupMappingTablesAction.pending, (state, action) => {
            state.groupMappingTables.data.records = null;
            state.groupMappingTables.apiMsg.message = action.meta.requestStatus;;
            state.groupMappingTables.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllGroupMappingTablesAction.rejected, (state, action) => {
            state.groupMappingTables.apiMsg.message = action.error.message;
            state.groupMappingTables.apiMsg.status = action.meta.requestStatus;
        });
        //------------------------------- getallgroupmappingtable end -------------------------

        //------------------------------- insertupdategroupmappingtable start -------------------------

        builder.addCase(
            insertUpdateGroupMappingTableAction.fulfilled,
            (state, action) => {

                state.insertUpdateGroupMappingTableDetails.data = action.payload.data.data;
                state.insertUpdateGroupMappingTableDetails.apiMsg.status = action.meta.requestStatus;
                state.insertUpdateGroupMappingTableDetails.apiMsg.message = action.payload.message.message;

                toast.success(action.payload.message.message, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "dept-error"
                });

            }
        );
        builder.addCase(
            insertUpdateGroupMappingTableAction.pending,
            (state, action) => {
                state.insertUpdateGroupMappingTableDetails.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            insertUpdateGroupMappingTableAction.rejected,
            (state, action) => {
                console.log(action.error.message);
                state.insertUpdateGroupMappingTableDetails.apiMsg.message = action.error.message;
                state.insertUpdateGroupMappingTableDetails.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "dept-error"
                });
            }
        );

        //------------------------------- insertupdategroupmappingtable end -------------------------



    },
});

export const { RESET, ResetSyncUtilityDetails } = SyncUtilityReducer.actions;
export default SyncUtilityReducer.reducer;
