import { createSlice } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import { getAllStockTransferAction, getStockTransferReportAction } from "../../../actions/reports/stock-reports/stockTransferAction";

const INITIAL_STATE = {
    stockTransfer: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    selectedStockTransfer: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
};

const stockTransferReducer = createSlice({
    name: "Reports",
    initialState: INITIAL_STATE,
    reducers: {
        RESET(state, action) {
            state.stockTransfer = INITIAL_STATE.stockTransfer;
        },
        ResetStockTransferDetailsState(state, action) {
            state.selectedStockTransfer = INITIAL_STATE.selectedStockTransfer;
        }

    },
    extraReducers: (builder) => {
        //------------------------------- getAllStockTransferAction start -------------------------
        // eslint-disable-next-line
        builder.addCase(getAllStockTransferAction.fulfilled, (state, action) => {
            state.stockTransfer.data = action.payload.data;
            state.stockTransfer.apiMsg.status = action.meta.requestStatus;
            // state.stockTransfer.apiMsg.message = action.payload.message.message;
        }),
            builder.addCase(getAllStockTransferAction.pending, (state, action) => {
                //console.log("getAllStockTransferAction pending", action);
                state.stockTransfer.apiMsg.status = action.meta.requestStatus;
            }),
            builder.addCase(getAllStockTransferAction.rejected, (state, action) => {
                //console.log("getAllStockTransferAction rejected", action);
                state.stockTransfer.apiMsg.message = action.error.message;
                state.stockTransfer.apiMsg.status = action.meta.requestStatus;
            })
        //------------------------------- getAllSalesSummaryReportACTION end -------------------------

                //------------ getStockTransferReportAction -----------

                builder.addCase(getStockTransferReportAction.fulfilled, (state, action) => {

                    state.selectedStockTransfer.data = action.payload.data;
                    state.selectedStockTransfer.apiMsg.status = action.meta.requestStatus;
                    state.selectedStockTransfer.apiMsg.message = action.payload.message.message;
                });
        
                builder.addCase(getStockTransferReportAction.pending, (state, action) => {
                    //console.log("getStockTransferReportAction pending", action);
                    state.selectedStockTransfer.data = null;
                    state.selectedStockTransfer.apiMsg.message = action.meta.requestStatus;;
                    state.selectedStockTransfer.apiMsg.status = action.meta.requestStatus;
                });
        
                builder.addCase(getStockTransferReportAction.rejected, (state, action) => {
                    // console.log("getStockTransferReportAction rejected", action);
                    state.selectedStockTransfer.apiMsg.message = action.error.message;
                    state.selectedStockTransfer.apiMsg.status = action.meta.requestStatus;
                });
        
        
                //   get manualPriceProduct end
    }
});

export const { RESET, ResetStockTransferDetailsState } = stockTransferReducer.actions;
export default stockTransferReducer.reducer;
