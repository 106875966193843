import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiCall, apiFileUploadCall } from "../../../API";
export const getAllCustomerInvoicesAction = createAsyncThunk(
    "CustomerInvoice/GetAllCustomerInvoices",
    async (data) => {
        const { filterData, query } = data;
        return apiCall(`customerinvoice/getall${query ? query : ""}`, "POST", filterData, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {

                        return {
                            ...response.data,
                        }
                    } else {
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);

export const getCustomerInvoiceAction = createAsyncThunk(
    "CustomerInvoice/GetCustomerInvoice",
    async (data) => {
        return apiCall("customerinvoice/getcustomerinvoice", "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {

                        return {
                            ...response.data,
                        }
                    } else {
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);


export const insertUpdateCustomerInvoiceAction = createAsyncThunk(
    "CustomerInvoice/insertupdatecustomerinvoice",
    async (data) => {
        return apiCall("customerinvoice/add", "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        let message = response.data.message
                        return {
                            data: {
                                ...response.data,
                            },
                            message: {
                                code: response.data.message.code,
                                type: response.data.message.type,
                                message: response.data.message.message
                            },
                            newRecord: data.id ? false : true
                        }
                    } else {
                        if (!response.data.message && response.data.errors && response.data.errors.length) {
                            response.data.message = response.data.errors[0];
                        }
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
                else {
                    return Promise.reject({
                        ...response.data,
                    });
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);

export const customerInvoiceActiveInactiveAction = createAsyncThunk(
    'CustomerInvoice/activeinactive',
    async (data) => {
        return apiCall("customerinvoice/activeinactive", "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        return {
                            ...response.data,
                        }
                    } else {
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
)

export const addCustomerInvoiceDocumentAction = createAsyncThunk(
    "CustomerInvoice/addCustomerInvoiceDocument",
    async (data) => {
        return apiFileUploadCall("customerinvoice/addcustomerinvoicedocument", "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        return {
                            ...response.data,
                        }
                    } else {
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);

export const getCustomerInvoiceDocumentsAction = createAsyncThunk(
    "CustomerInvoice/getCustomerDocuments",
    async (data) => {
        return apiCall("customerinvoice/getCustomerInvoiceDocuments", "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        return {
                            ...response.data,
                        }
                    } else {
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);


export const deleteCustomerInvoiceDocumentsAction = createAsyncThunk(
    "CustomerInvoice/removeCustomerInvoiceDocuments",
    async (data) => {
        return apiCall("customerinvoice/removeCustomerInvoiceDocument", "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {

                        return {
                            ...response.data,
                        }
                    } else {
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);

export const getAllProductsAction = createAsyncThunk(
    "CustomerInvoice/getAllProducts",
    async (data) => {
        return apiCall(`customerInvoice/getproductforcustomerinvoice${data.query ? data.query : ""}`, "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        return {
                            ...response.data,
                        }
                    } else {
                        //  console.log("response error", response.data.message);
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);

export const getAllInvoiceProductsAction = createAsyncThunk(
    "CustomerInvoice/getAllInvoiceProductsAction",
    async (data) => {
        return apiCall(`customerInvoice/getproductforcustomerinvoice${data.query ? data.query : ""}`, "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        return {
                            ...response.data,
                        }
                    } else {
                        //  console.log("response error", response.data.message);
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);
export const invoicePromotionCalculationAction = createAsyncThunk(
    "CustomerInvoice/invoicePromotionCalculationAction",
    async (data) => {
        return apiCall(`customerInvoice/PromotionCalculations`, "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        return {
                            ...response.data,
                        }
                    } else {
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);






